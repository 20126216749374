import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { GROUPS } from '../utils/constants'

const useRequirePermission = (permission: GROUPS) => {
  const history = useHistory()
  const { isLoading, groups } = useSelector((state) => state.auth)

  useEffect(() => {
    if (!isLoading && !groups.includes(permission)) {
      history.push('/')
    }
  }, [groups, history, isLoading, permission])
}

export default useRequirePermission
