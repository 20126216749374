import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import ActionPaneView from './ActionPaneView'
import Breadcrumbs from './Breadcrumbs'
import DeprecatedEntityListPane from './DeprecatedEntityListPane'
import Spinner from './Spinner'
import StyledLink from './StyledLink'
import Table, { TableHeaderCellStyles } from './Table'
import TabPane from './Tabs/TabPane'
import Title from './Title'
import VerticalTable from './VerticalTable'
import FeatureValidator from '../authorization/components/FeatureValidator'
import features from '../authorization/features'
import type { FTUserPermissions } from '../authorization/models/userPermissions'
import { selectors as authSelectors } from '../ducks/auth'
import {
  actions as customerActions,
  selectCustomerEntity,
} from '../ducks/customers'
import { actions as messageFlasherActions } from '../ducks/messageFlasher'
import { actions as modalActions } from '../ducks/modal'
import {
  actions as userActions,
  denaliUserGroupsApiNames,
  maUserRolesApiNames,
  selectUserEntity,
  selectUserListEntityMeta,
} from '../ducks/users'
import type { FTEntityPayload } from '../ducks/utils'
import { renderTimestamp } from '../ducks/utils'
import FTWithRouter from '../types'
import { naturallySortItems } from '../utils'

type FTProps = FTWithRouter & {
  isAdmin: boolean
  isMaOnlyUser: boolean
  isMaUser: boolean
  updateLoading: boolean
  sendingMaResetEmail: boolean
  sendingMaWelcomeEmail: boolean
  permissions: FTUserPermissions
  sendingR1ResetEmail: boolean
  sendingR1WelcomeEmail: boolean
}
type FTState = {
  activating: boolean
  deactivating: boolean
}
const PageComponent = styled.div``
const DeactivateConfirmModalContentStyled = styled.div`
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`
const TableWrapperStyled = styled.div`
  width: 700px;
  ${TableHeaderCellStyles} {
  }
`
// TODO: Update the source map as required
const userSourceMap = {
  WORKMARKET: 'Workmarket',
  REDAPTIVE: 'Grand Central',
}

const coalesceAssociations = (customers = [], sites = []) => {
  const ids = {}
  sites.forEach((s) => {
    ids[s.customerId] = { ...s, id: s.customerId, name: s.customerName }
  })
  customers.forEach((c) => {
    if (!ids[c.id]) {
      ids[c.id] = c
    }
  })
  return Object.values(ids).sort(naturallySortItems)
}

class UserDetail extends PureComponent<FTProps, FTState> {
  constructor(props: FTProps) {
    super(props)
    this.state = {
      activating: false,
      deactivating: false,
    }
  }

  componentDidMount() {
    const {
      actions,
      match: {
        params: { userId },
      },
    } = this.props
    actions.loadUser(userId)
  }

  componentDidUpdate(prevProps: FTProps) {
    const {
      updateLoading: updateLoadingPrev,
      sendingMaResetEmail: sendingMaResetEmailPrev,
      sendingMaWelcomeEmail: sendingMaWelcomeEmailPrev,
      sendingR1ResetEmail: sendingR1ResetEmailPrev,
      sendingR1WelcomeEmail: sendingR1WelcomeEmailPrev,
    } = prevProps
    const {
      actions,
      updateLoading,
      sendingMaResetEmail,
      sendingMaWelcomeEmail,
      sendingR1ResetEmail,
      sendingR1WelcomeEmail,
      userEntity,
    } = this.props
    const { item: user, meta } = userEntity || {}
    const { email } = user || {}
    const { error = '' } = meta || {}
    const { activating, deactivating } = this.state
    if (sendingMaWelcomeEmailPrev && !sendingMaWelcomeEmail) {
      if (!error) {
        actions.showMessage({
          title: `Meter Install App Welcome Email has been successfully sent to the User ${email}`,
        })
      }
    }
    if (sendingR1WelcomeEmailPrev && !sendingR1WelcomeEmail) {
      if (!error) {
        actions.showMessage({
          title: `Redaptive ONE Welcome Email has been successfully sent to the User ${email}`,
        })
      }
    }

    if (sendingMaResetEmailPrev && !sendingMaResetEmail) {
      if (!error) {
        actions.showMessage({
          title: `Meter Install App Reset Email has been successfully sent to the User ${email}`,
        })
      }
    }
    if (sendingR1ResetEmailPrev && !sendingR1ResetEmail) {
      if (!error) {
        actions.showMessage({
          title: `Redaptive ONE Reset Email has been successfully sent to the User ${email}`,
        })
      }
    }

    if (updateLoadingPrev && !updateLoading) {
      if (activating) {
        this.setState({
          activating: false,
        })

        if (!error) {
          actions.showMessage({
            title: `User ${email} has been successfully activated.`,
          })
        }
      }

      if (deactivating) {
        this.setState({
          deactivating: false,
        })

        if (!error) {
          actions.showMessage({
            title: `User ${email} has been successfully deactivated.`,
          })
        }
      }
    }
  }

  canEditUser = (): boolean => {
    const { isAdmin, permissions, userEntity } = this.props
    const { item: user } = userEntity || {}
    const { isAdmin: displayedUserIsAdmin } = user || {}
    return (
      isAdmin ||
      (!displayedUserIsAdmin &&
        features.updateUsers.allMatchWithPermissions(permissions))
    )
  }

  renderAdmin = () => (
    <div>
      This user is a Redaptive Administrator and has access to all current and
      future customers and sites.
    </div>
  )

  renderStandardUser = () => {
    const {
      actions,
      location: { pathname },
      match: {
        url,
        params: { userId },
      },
      userEntity: {
        meta,
        item: { isDashboardUserOnly, associatedCustomers, associatedSites },
      },
    } = this.props

    const tableHeaders = [
      {
        fieldName: 'name',
        displayName: 'Customer Name',
      },
      {
        fieldName: 'siteAccess',
        displayName: 'Site Access',
      },
    ]
    const hasAssociatedSites = associatedSites.length > 0
    const hasAssociatedCustomers = associatedCustomers.length > 0
    const allCustomers =
      hasAssociatedSites || hasAssociatedCustomers ?
        coalesceAssociations(associatedCustomers, associatedSites)
      : []
    const feature =
      isDashboardUserOnly ?
        features.manageDashboardUserPermissions
      : features.manageAllUserPermissions
    const entity: FTEntityPayload = {
      items: allCustomers,
      meta: {
        totalPages: 1,
        totalCount: meta.totalCount,
        loading: meta.loading,
        pageNumber: 1,
        pageSize: 100,
        next: null,
        previous: null,
      },
    }

    const renderTableRow = ({ id, name }) => {
      const sites =
        associatedSites ?
          associatedSites
            .filter(({ customerId }) => customerId === id)
            .sort(naturallySortItems)
        : []
      const customerLink = `/account-management/customers/${id}`
      const siteLink = `${customerLink}/sites`
      return (
        <tr key={id}>
          <td className='customer'>
            <Link to={customerLink}>{name}</Link>
          </td>
          <td>
            {!!sites.length &&
              sites.map((site) => (
                <>
                  <Link
                    to={url.replace(`/users/${userId}`, `/sites/${site.id}`)}
                  >
                    {site.name}
                  </Link>
                  <br />
                </>
              ))}
            {!sites.length && (
              <Link to={siteLink}>All Current and Future Sites</Link>
            )}
          </td>
        </tr>
      )
    }

    return (
      <DeprecatedEntityListPane
        striped
        showPageSizer={false}
        entity={entity}
        loadEntity={actions.loadUser}
        tableHeaders={tableHeaders}
        renderTableRow={renderTableRow}
        renderNoResults={() => {
          const invalidMessage =
            "You do not have permissions to edit this user's customer access."
          return (
            <div>
              This user has not been granted access to any customers.
              <FeatureValidator
                feature={feature}
                requireAll={!isDashboardUserOnly}
                onInvalid={() => (
                  <div
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    {invalidMessage}
                  </div>
                )}
              >
                <StyledLink
                  style={{
                    marginTop: '20px',
                  }}
                  to={`${pathname}/edit`}
                  href={`${pathname}/edit`}
                >
                  Edit Permissions
                </StyledLink>
              </FeatureValidator>
            </div>
          )
        }}
      />
    )
  }

  renderCustomers = () => {
    const { userEntity } = this.props
    return (
      <div className='CustomersPane'>
        <div className='CustomersPane-header'>
          <div className='SearchInput' />
          <div className='PageSizeSelector' />
        </div>
        <div className='CustomersPane-body'>
          {!userEntity.meta.loading &&
            (userEntity.item.isAdmin ?
              this.renderAdmin()
            : this.renderStandardUser())}
        </div>
      </div>
    )
  }

  renderEvents = () => {
    const {
      userEntity: { item: user },
    } = this.props
    const eventHeaders = [
      {
        fieldName: 'emailTemplate',
        displayName: 'Email Template',
      },
      {
        fieldName: 'lastSent',
        displayName: 'Last sent',
      },
    ]
    const eventItems = [
      {
        fieldName: 'Redaptive Meter Install App - Welcome Email',
        lastSent: renderTimestamp(user.inviteSent),
      },
      {
        fieldName: 'Redaptive Meter Install App - Password Reset Email',
        lastSent: renderTimestamp(user?.lastResetPasswordEmailSentOn),
      },
      {
        fieldName: 'Redaptive ONE - Welcome Email',
        lastSent: renderTimestamp(user?.inviteSentR1),
      },
    ]
    return (
      <TableWrapperStyled>
        <Table
          loading={false}
          browseToPage={() => {}}
          totalCount={3}
          totalPages={1}
          updateSort={() => {}}
          striped
          tableHeaders={eventHeaders}
          items={eventItems}
          renderTableRow={({ id, fieldName, lastSent }) => (
            <tr key={id}>
              <td>{fieldName}</td>
              <td>{lastSent}</td>
            </tr>
          )}
        />
      </TableWrapperStyled>
    )
  }

  renderBreadcrumbs = () => {
    const {
      match: {
        params: { customerId },
      },
      customerEntity: { item: customer },
    } = this.props
    const items =
      customerId ?
        [
          {
            href: '/account-management',
            text: 'Accounts',
          },
          {
            href: '/account-management/customers',
            text: 'Customers',
          },
          {
            href: `/account-management/customers/${customerId}`,
            text: customer ? customer.name : '',
          },
          {
            href: `/account-management/customers/${customerId}/users`,
            text: 'Users',
          },
        ]
      : [
          {
            href: '/account-management',
            text: 'Accounts',
          },
          {
            href: '/account-management/users',
            text: 'Users',
          },
        ]
    return <Breadcrumbs items={items} />
  }

  renderMain = () => {
    const {
      userEntity: { item: user },
      customerEntity: {
        meta: { loading: customerLoading },
      },
    } = this.props
    const { activating, deactivating } = this.state || {}
    const tabs = [
      {
        tab: 'permissions',
        text: 'Access Permissions',
        render: this.renderCustomers,
      },
      {
        tab: 'events',
        text: 'Email Events',
        render: this.renderEvents,
      },
    ]
    let fields = []

    if (user) {
      fields = [
        {
          label: 'Email Address',
          value: user.email,
          editable: false,
        },
        {
          label: 'Status',
          renderField: () => (
            <>
              {(activating || deactivating) && (
                <span>
                  {'Updating... '}
                  <Spinner inline size='micro' />
                </span>
              )}
              {!(activating || deactivating) && user.status}
            </>
          ),
          editable: false,
        },
        {
          label: 'User Group',
          value: user.userType,
          editable: false,
        },
        {
          label: 'Source',
          value: userSourceMap[user.source] || '',
        },
        {
          label: 'Source ID',
          value: user.workMarketResourceId,
        },
        {
          label: 'First Name',
          value: user.firstName,
        },
        {
          label: 'Last Name',
          value: user.lastName,
        },
        {
          label: 'Phone',
          value: user.phone,
        },
        {
          label: 'Company',
          value: user.company,
        },
      ]
    }

    return (
      <PageComponent>
        <div className='UserDetailPage-main'>
          {!customerLoading && this.renderBreadcrumbs()}
          <Title>User Detail</Title>
          {user && <VerticalTable.Basic fields={fields} columned />}
          {user && <TabPane tabs={tabs} />}
        </div>
      </PageComponent>
    )
  }

  render() {
    const {
      actions,
      isMaUser,
      isMaOnlyUser,
      isR1User,
      match: {
        url,
        params: { userId },
      },
      userEntity: { item: user },
    } = this.props
    const { email, id, status } = user || {}
    const actionPaneActions = []
    const canEditUser = this.canEditUser()
    const editUserLink = {
      href: `${url}/edit`,
      label: 'Edit User',
    }

    if (canEditUser) {
      actionPaneActions.push(editUserLink)
    }

    const getResetPasswordLinkAction = {
      href: '',
      label: 'Get Password Reset Link',
      onClick: () => {
        actions.showModalResetPasswordLink({
          email,
        })
      },
    }
    const sendMaWelcomeEmailAction = {
      href: '',
      label: 'Send Redaptive Meter Install App Welcome Email',
      onClick: () => {
        actions.sendMaWelcomeEmail({
          email,
        })
      },
    }
    const sendMaPasswordResetEmailAction = {
      href: '',
      label: 'Send Redaptive Meter Install App Password Reset Email',
      onClick: () => {
        actions.sendMaResetEmail({
          email,
        })
      },
    }

    const sendR1WelcomeEmailAction = {
      href: '',
      label: 'Send Redaptive ONE Welcome Email',
      onClick: () => {
        actions.sendR1WelcomeEmail({ email })
      },
    }

    const sendR1PasswordResetEmailAction = {
      href: '',
      label: 'Send Redaptive ONE Password Reset Email',
      onClick: () => {
        actions.sendR1ResetEmail({ email })
      },
    }

    const getSignupLinkAction = {
      href: '',
      label: 'Get Signup Link',
      onClick: () => {
        actions.showModalSignupLink({
          email,
          id,
        })
      },
    }
    const deactivateLinkAction = {
      href: '',
      label: 'Deactivate User',
      onClick: () => {
        actions.showConfirmModal({
          modalWidth: '430px',
          onPrimaryAction: () => {
            this.setState({
              deactivating: true,
            })
            actions.deactivateUser({
              id: userId,
            })
          },
          primaryActionText: 'Yes',
          renderContent: () => (
            <DeactivateConfirmModalContentStyled>
              {`Are you sure you want to Deactivate ${email}?`}
            </DeactivateConfirmModalContentStyled>
          ),
          secondaryActionText: 'No',
        })
      },
    }

    if (status === 'Active' && canEditUser) {
      actionPaneActions.push(deactivateLinkAction)

      if (!isMaOnlyUser) {
        actionPaneActions.push(getResetPasswordLinkAction)
      }

      if (isMaUser) {
        actionPaneActions.push(sendMaPasswordResetEmailAction)
        actionPaneActions.push(sendMaWelcomeEmailAction)
      }
      if (isR1User) {
        actionPaneActions.push(sendR1PasswordResetEmailAction)
      }
    } else if (status === 'Inactive' && canEditUser) {
      actionPaneActions.push({
        href: '',
        label: 'Activate User',
        onClick: () => {
          this.setState({
            activating: true,
          })
          actions.activateUser({
            id: userId,
          })
        },
      })
      actionPaneActions.push(getResetPasswordLinkAction)
    } else if (status === 'Pending' && canEditUser) {
      if (!isMaOnlyUser) {
        actionPaneActions.push(getSignupLinkAction)
      }

      if (isMaUser) {
        actionPaneActions.push(sendMaWelcomeEmailAction)
      }
      if (isR1User) {
        actionPaneActions.push(sendR1WelcomeEmailAction)
      }
    }
    return (
      <ActionPaneView
        renderMain={this.renderMain}
        actions={actionPaneActions}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(messageFlasherActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(userActions, dispatch),
  },
})

const mapStateToProps = (state, props) => {
  const {
    updateLoading,
    sendingMaWelcomeEmail,
    sendingMaResetEmail,
    sendingR1WelcomeEmail,
    sendingR1ResetEmail,
  } = selectUserListEntityMeta(state)
  const authGroups = authSelectors.selectGroups(state)
  const isAdmin = authGroups && authGroups.includes('admin')
  const userEntity = selectUserEntity(state, props.match.params.userId)
  const userGroups = userEntity.item?.groups
  const isMaUser =
    userGroups &&
    userGroups.length &&
    maUserRolesApiNames.some((group) => userGroups.includes(group))
  const isR1User =
    userGroups &&
    userGroups.length &&
    denaliUserGroupsApiNames.some((group) => userGroups.includes(group))
  const isMaOnlyUser =
    userGroups &&
    userGroups.length &&
    userGroups.every((group) => maUserRolesApiNames.includes(group))
  return {
    customerEntity: selectCustomerEntity(state, props.match.params.customerId),
    isAdmin,
    isMaOnlyUser,
    isMaUser,
    isR1User,
    permissions: authSelectors.selectPermissions(state),
    updateLoading,
    sendingMaWelcomeEmail,
    sendingMaResetEmail,
    sendingR1WelcomeEmail,
    sendingR1ResetEmail,
    userEntity,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDetail),
)
