const areArrayValuesEven = (arr: number[]): boolean =>
  arr.every((val) => val % 2 === 0)
const areArrayValuesOdd = (arr: number[]): boolean =>
  arr.every((val) => val % 2 !== 0)
const areArrayValuesConsecutive = (arr: number[]): boolean => {
  const sortedArr = arr.sort((a, b) => a - b)
  return sortedArr.every((val, i) => i === 0 || val - sortedArr[i - 1] === 1)
}

export const findArrayType = (arr: number[]): string => {
  if (areArrayValuesEven(arr)) {
    return 'EVEN'
  }
  if (areArrayValuesOdd(arr)) {
    return 'ODD'
  }
  if (areArrayValuesConsecutive(arr)) {
    return 'CONSECUTIVE'
  }
  return 'MIXED'
}
