import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { utils as userSummaryUtils } from './userSummaries'
import { consoleApiUrl, defaultHeaders } from '../api'
import { getTotalPages, handleAxiosError, queryStringify } from '../api/utils'
import * as globalTypes from '../constants/actionTypes'
import * as statusConstants from '../constants/status'
import { handleError, handleSagaError } from '../sagas/utils'
import { GROUPS } from '../utils/constants'

export const SIGNUP_REQUEST_KEY = 'signupForm'
export const USER_RESET_REQUEST_KEY = 'resetForm'
export type FTCreateUserAction = {
  associatedCustomers: Array<string>
  associatedSites: Array<string>
  groups: Array<string>
  email: string
  username?: string
}
type FTCreateUserRequest = {
  associatedCustomers: Array<string>
  associatedSites: Array<string>
  groups: Array<string>
  email: string
  username: string
}
export type FTUpdateUserAction = {
  id: string
  status?: 'PENDING' | 'ACTIVE' | 'INACTIVE'
  associatedCustomers?: Array<string>
  associatedSites?: Array<string>
  groups?: Array<string>
  email?: string
  username?: string
}
export type FTActivateUserAction = {
  id: string
}
export type FTDeactivateUserAction = FTActivateUserAction
type FTUserResponse = {
  associatedCustomers: Array<{
    id: string
    name: string
  }>
  associatedSites: Array<{
    id: string
    name: string
    customerId: string
  }>
  company: string
  email: string
  firstName: string
  groups: Array<string>
  id: string
  inviteSent: string
  inviteSentR1: string
  lastResetPasswordEmailSentOn: string
  lastActivity: string
  lastName: string
  workMarketResourceId: string
  phone: string
  safetyTrainingVideosChecked: boolean
  source: string
  status: string
  termsAndConditionsChecked: boolean
  username: string
  activationOrResetPasswordToken: string
}
export type FTUser = {
  isAdmin: boolean
  isDashboardUser: boolean
  isDashboardUserOnly: boolean
  isDenaliUser: boolean
  username: string
  userType: string
} & FTUserResponse
type FTCreateUpdateUserException = {
  name: string
  errors: Array<string>
  toString: string
}
export const types = {
  CLEAR_CREATE_USER_ID: 'CLEAR_CREATE_USER_ID',
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  FETCH_USER_INFO: 'FETCH_USER_INFO',
  FETCH_USER_INFO_SUCCESS: 'FETCH_USER_INFO_SUCCESS',
  FETCH_USER_INFO_ERROR: 'FETCH_USER_INFO_ERROR',
  LOAD_ALL_USERS: 'LOAD_ALL_USERS',
  LOAD_ALL_USERS_SUCCESS: 'LOAD_ALL_USERS_SUCCESS',
  LOAD_ALL_USERS_ERROR: 'LOAD_ALL_USERS_ERROR',
  LOAD_USER: 'LOAD_USER',
  LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
  LOAD_USER_ERROR: 'LOAD_USER_ERROR',
  PARTIAL_UPDATE_USER: 'PARTIAL_UPDATE_USER',
  PARTIAL_UPDATE_USER_SUCCESS: 'PARTIAL_UPDATE_USER_SUCCESS',
  PARTIAL_UPDATE_USER_ERROR: 'PARTIAL_UPDATE_USER_ERROR',
  RESET_USER: 'RESET_USER',
  RESET_USER_CLEAR: 'RESET_USER_CLEAR',
  RESET_USER_SUCCESS: 'RESET_USER_SUCCESS',
  RESET_USER_ERROR: 'RESET_USER_ERROR',
  MA_SEND_RESET_EMAIL: 'MA_SEND_RESET_EMAIL',
  MA_SEND_RESET_EMAIL_SUCCESS: 'MA_SEND_RESET_EMAIL_SUCCESS',
  MA_SEND_RESET_EMAIL_ERROR: 'MA_SEND_RESET_EMAIL_ERROR',
  R1_SEND_RESET_EMAIL: 'R1_SEND_RESET_EMAIL',
  R1_SEND_RESET_EMAIL_SUCCESS: 'R1_SEND_RESET_EMAIL_SUCCESS',
  R1_SEND_RESET_EMAIL_ERROR: 'R1_SEND_RESET_EMAIL_ERROR',
  MA_SEND_WELCOME_EMAIL: 'MA_SEND_WELCOME_EMAIL',
  MA_SEND_WELCOME_EMAIL_SUCCESS: 'MA_SEND_WELCOME_EMAIL_SUCCESS',
  MA_SEND_WELCOME_EMAIL_ERROR: 'MA_SEND_WELCOME_EMAIL_ERROR',
  R1_SEND_WELCOME_EMAIL: 'R1_SEND_WELCOME_EMAIL',
  R1_SEND_WELCOME_EMAIL_SUCCESS: 'R1_SEND_WELCOME_EMAIL_SUCCESS',
  R1_SEND_WELCOME_EMAIL_ERROR: 'R1_SEND_WELCOME_EMAIL_ERROR',
  SIGNUP_USER: 'SIGNUP_USER',
  SIGNUP_USER_CLEAR: 'SIGNUP_USER_CLEAR',
  SIGNUP_USER_SUCCESS: 'SIGNUP_USER_SUCCESS',
  SIGNUP_USER_ERROR: 'SIGNUP_USER_ERROR',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  UPDATE_USER_ASSOCIATIONS: 'UPDATE_USER_ASSOCIATIONS',
  UPDATE_USER_ASSOCIATIONS_SUCCESS: 'UPDATE_USER_ASSOCIATIONS_SUCCESS',
  UPDATE_USER_ASSOCIATIONS_ERROR: 'UPDATE_USER_ASSOCIATIONS_ERROR',
  SIGNUP_AND_RESET_PASSWORD_TOKEN: 'SIGNUP_AND_RESET_PASSWORD_TOKEN',
  SIGNUP_AND_RESET_PASSWORD_TOKEN_SUCCESS:
    'SIGNUP_AND_RESET_PASSWORD_TOKEN_SUCCESS',
  SIGNUP_AND_RESET_PASSWORD_TOKEN_ERROR:
    'SIGNUP_AND_RESET_PASSWORD_TOKEN_ERROR',
}
const userStatusMap = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PENDING: 'Pending',
}
export type FTGroupsMap = Record<
  string,
  {
    displayName: string
    apiName: string
  }
>
export const gcUserGroupsMap: FTGroupsMap = {
  accountManagers: {
    displayName: 'Account Manager',
    apiName: 'account-managers',
  },
  operationsSupport: {
    displayName: 'Operations Support',
    apiName: 'operations-support',
  },
  proposalOperations: {
    displayName: 'Proposal Operations and Lighting Audit Validation',
    apiName: 'proposal-operations',
  },
  proposalOperationsAdmin: {
    displayName: 'Proposal Operations Administrator',
    apiName: 'proposal-operations-admin',
  },
  proposalOperationsReadonly: {
    displayName: 'Proposal Operations Readonly',
    apiName: 'proposal-operations-readonly',
  },
  admin: {
    displayName: 'Administrator',
    apiName: 'admin',
  },
  energyStarAdmin: {
    displayName: 'Energy Star Administrator',
    apiName: GROUPS.ENERGY_STAR_ADMIN,
  },
  hvacAssetsUploader: {
    displayName: 'HVAC Assets Uploader',
    apiName: 'hvac-assets-uploader',
  },
  ltgAsBuiltUploader: {
    displayName: 'Lighting As Built Uploader',
    apiName: 'lgt-as-built-uploader',
  },
  billing: {
    displayName: 'Billing',
    apiName: 'billing',
  },
  billingReadOnly: {
    displayName: 'Billing Read-Only',
    apiName: 'billing-read-only',
  },
  billingIssueInvestigator: {
    displayName: 'Billing Issue Investigator',
    apiName: 'billing-issue-investigator',
  },
  billingPerformanceReviewer: {
    displayName: 'Billing Performance Reviewer',
    apiName: 'billing-performance-reviewer',
  },
  billingInvoiceApprover: {
    displayName: 'Billing Invoice Approver',
    apiName: 'billing-invoice-approver',
  },
  manufacturing: {
    displayName: 'Manufacturing',
    apiName: 'manufacturing',
  },
  user: {
    displayName: 'Dashboard User',
    apiName: 'user',
  },
  denaliUser: {
    displayName: 'Redaptive ONE User',
    apiName: 'denali-user',
  },
  isrProcessorTool: {
    displayName: 'ISR Processor Tool',
    apiName: 'isr-processor-tool',
  },
}
export const denaliUserGroupsMap: FTGroupsMap = {
  denaliAdmin: {
    displayName: 'Redaptive ONE Admin',
    apiName: 'denali-admin',
  },
  denaliUser: {
    displayName: 'Redaptive ONE User',
    apiName: 'denali-user',
  },
  performanceAndInvoicesUser: {
    displayName: 'Performance and Invoices Access',
    apiName: 'r1-performance-invoice-views',
  },
  energyStarCoordinator: {
    displayName: 'Energy Star Coordinator',
    apiName: 'energy-star-coordinator',
  },
}

export const maUserGroupsMap: FTGroupsMap = {
  mobileAppMeterInstaller: {
    displayName: 'Installer',
    apiName: 'meter-install-app-access',
  },
  mobileAppAdministrator: {
    displayName: 'Administrator',
    apiName: 'meter-install-app-admin-access',
  },
}

export const denaliUserGroupsApiNames = Object.values(denaliUserGroupsMap)
  // $FlowFixMe
  .map<string>((userGroup) => userGroup.apiName)

export const maUserRolesApiNames = Object.keys(maUserGroupsMap).map<string>(
  (userGroup) => maUserGroupsMap[userGroup].apiName,
)
export const r1UserRolesApiNames = Object.keys(denaliUserGroupsMap).map<string>(
  (userGroup) => denaliUserGroupsMap[userGroup].apiName,
)

export const userGroupsMap: FTGroupsMap = {
  ...denaliUserGroupsMap,
  ...gcUserGroupsMap,
  ...maUserGroupsMap,
}
// Utils
const utils = {
  enhanceUser: (user: FTUserResponse): FTUser => {
    const { groups, status } = user
    return {
      ...user,
      isAdmin: utils.isAdmin(user),
      isDashboardUser: groups.includes('user'),
      isDashboardUserOnly: utils.isDashboardUserOnly(user),
      isDenaliUser: groups.some((group) =>
        ['denali-admin', 'denali-user'].includes(group),
      ),
      status: userStatusMap[status],
      userType: userSummaryUtils.getUserType(user.groups || []),
    }
  },
  // $FlowFixMe
  isAdmin: (user: FTUserResponse | null | undefined): boolean =>
    !!user?.groups.includes('admin'),
  isDashboardUserOnly: (
    user: FTUserResponse | null | undefined,
  ): boolean => // $FlowFixMe
    !!(user?.groups.length === 1 && user?.groups[0].toLowerCase() === 'user'),
}
type FTUserEntityState = {
  byId: Record<string, FTUser>
  allIds: Array<string>
  meta: {
    createLoading: boolean
    createUserId: string
    error: string
    loading: boolean
    updateLoading: boolean
    sendingMaWelcomeEmail: boolean
    sendingMaResetEmail: boolean
    sendingR1WelcomeEmail: boolean
    sendingR1ResetEmail: boolean
    pageNumber: number | null | undefined
    pageSize: number | null | undefined
    next: string | null | undefined
    previous: string | null | undefined
  }
  reset: {
    resetId: string | null | undefined
    email: string | null | undefined
  }
  signup: {
    signupId: string | null | undefined
    email: string | null | undefined
  }
  userForm: {
    errors: Array<string>
    loading: boolean
    loaded: boolean
  }
  signupAndResetPasswordToken: {
    token: string
    loading: boolean
    error: string
  }
}
type FTState = {
  entities: {
    users: FTUserEntityState
  }
}
const initialState = {
  byId: {},
  allIds: [],
  meta: {
    error: '',
    loading: false,
    createLoading: false,
    createUserId: '',
    updateLoading: false,
    sendingMaWelcomeEmail: false,
    sendingMaResetEmail: false,
    sendingR1WelcomeEmail: false,
    sendingR1ResetEmail: false,
    pageNumber: 1,
    pageSize: 12,
    next: null,
    previous: null,
  },
  reset: {
    resetId: null,
    email: null,
  },
  signup: {
    signupId: null,
    email: null,
  },
  userForm: {
    errors: [],
    loading: false,
    loaded: false,
  },
  signupAndResetPasswordToken: {
    error: '',
    loading: false,
    token: '',
  },
}
const userSchema = new schema.Entity('users')

function userById(action, state) {
  return { ...state, ...action.payload.entities.users }
}

function userAllIds(action, state) {
  return [...new Set(state.concat(action.payload.result))]
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.CREATE_USER_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return userById(action, state)

    case types.LOAD_ALL_USERS:
    case types.LOAD_USER:
      return {}

    case types.LOAD_ALL_USERS_SUCCESS:
    case types.LOAD_USER_SUCCESS:
      return userById(action, state)

    default:
      return state
  }
}

function allIds(state = initialState.allIds, action) {
  switch (action.type) {
    case types.CREATE_USER_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return userAllIds(action, state)

    case types.LOAD_ALL_USERS:
    case types.LOAD_USER:
      return []

    case types.LOAD_ALL_USERS_SUCCESS:
    case types.LOAD_USER_SUCCESS:
      return userAllIds(action, state)

    default:
      return state
  }
}

function signup(state = initialState.signup, action) {
  switch (action.type) {
    case types.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        signupId: action.payload.signupId,
        email: action.payload.email,
      }

    case types.SIGNUP_USER_CLEAR:
      return initialState.signup

    default:
      return state
  }
}

function reset(state = initialState.reset, action) {
  switch (action.type) {
    case types.RESET_USER_SUCCESS:
      return {
        ...state,
        resetId: action.payload.resetId,
        email: action.payload.email,
      }

    case types.RESET_USER_CLEAR:
      return initialState.reset

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.LOAD_ALL_USERS:
    case types.LOAD_USER:
      return { ...state, loading: true }

    case types.LOAD_ALL_USERS_ERROR:
    case types.LOAD_USER_ERROR:
      return { ...state, loading: false }

    case types.LOAD_ALL_USERS_SUCCESS:
    case types.LOAD_USER_SUCCESS:
      return { ...state, ...action.payload.meta, loading: false }

    case types.PARTIAL_UPDATE_USER:
    case types.UPDATE_USER_ASSOCIATIONS:
    case types.UPDATE_USER:
      return { ...state, updateLoading: true }

    case types.MA_SEND_RESET_EMAIL:
      return {
        ...state,
        sendingMaResetEmail: true,
      }
    case types.R1_SEND_RESET_EMAIL:
      return {
        ...state,
        sendingR1ResetEmail: true,
      }
    case types.MA_SEND_WELCOME_EMAIL:
      return {
        ...state,
        sendingMaWelcomeEmail: true,
      }
    case types.R1_SEND_WELCOME_EMAIL:
      return {
        ...state,
        sendingR1WelcomeEmail: true,
      }
    case types.PARTIAL_UPDATE_USER_ERROR:
    case types.UPDATE_USER_ASSOCIATIONS_ERROR:
    case types.UPDATE_USER_ERROR:
      return { ...state, error: action.error, updateLoading: false }

    case types.MA_SEND_RESET_EMAIL_ERROR:
      return {
        ...state,
        error: action.error,
        sendingMaResetEmail: false,
      }
    case types.R1_SEND_RESET_EMAIL_ERROR:
      return {
        ...state,
        error: action.error,
        sendingR1ResetEmail: false,
      }
    case types.MA_SEND_WELCOME_EMAIL_ERROR:
      return {
        ...state,
        error: action.error,
        sendingMaWelcomeEmail: false,
      }
    case types.R1_SEND_WELCOME_EMAIL_ERROR:
      return {
        ...state,
        error: action.error,
        sendingR1WelcomeEmail: false,
      }
    case types.PARTIAL_UPDATE_USER_SUCCESS:
    case types.UPDATE_USER_ASSOCIATIONS_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return { ...state, updateLoading: false, error: '' }

    case types.MA_SEND_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        sendingMaResetEmail: false,
        error: '',
      }
    case types.R1_SEND_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        sendingR1ResetEmail: false,
        error: '',
      }
    case types.MA_SEND_WELCOME_EMAIL_SUCCESS:
      return {
        ...state,
        sendingMaWelcomeEmail: false,
        error: '',
      }
    case types.R1_SEND_WELCOME_EMAIL_SUCCESS:
      return {
        ...state,
        sendingR1WelcomeEmail: false,
        error: '',
      }
    case types.CREATE_USER:
      return { ...state, createLoading: true, createUserId: '' }

    case types.CREATE_USER_ERROR:
      return { ...state, error: action.error, createLoading: false }

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createUserId: action.payload.result[0],
        error: '',
      }

    case types.CLEAR_CREATE_USER_ID:
      return { ...state, createUserId: '' }

    default:
      return state
  }
}

function userForm(state = initialState.userForm, action) {
  switch (action.type) {
    case types.CREATE_USER:
    case types.UPDATE_USER:
      return {
        errors: [],
        loading: true,
        loaded: false,
      }

    case types.CREATE_USER_SUCCESS:
    case types.UPDATE_USER_SUCCESS:
      return {
        errors: [],
        loading: false,
        loaded: true,
      }

    case types.CREATE_USER_ERROR:
    case types.UPDATE_USER_ERROR:
      return {
        errors: action.errors,
        loading: false,
        loaded: true,
      }

    default:
      return state
  }
}

function signupAndResetPasswordToken(
  state = initialState.signupAndResetPasswordToken,
  action,
) {
  switch (action.type) {
    case types.SIGNUP_AND_RESET_PASSWORD_TOKEN:
      return {
        token: '',
        loading: true,
        error: '',
      }
    case types.SIGNUP_AND_RESET_PASSWORD_TOKEN_SUCCESS:
      return {
        token: action.payload,
        loading: false,
        error: '',
      }
    case types.SIGNUP_AND_RESET_PASSWORD_TOKEN_ERROR:
      return {
        token: '',
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  signup,
  reset,
  meta,
  userForm,
  signupAndResetPasswordToken,
})
export const actions = {
  activateUser: (params: FTActivateUserAction) => ({
    type: types.UPDATE_USER,
    ...params,
    status: 'ACTIVE',
  }),
  clearCreateUserId: () => ({
    type: types.CLEAR_CREATE_USER_ID,
  }),
  deactivateUser: (params: FTDeactivateUserAction) => ({
    type: types.UPDATE_USER,
    ...params,
    status: 'INACTIVE',
  }),
  clearSignup: () => ({
    type: types.SIGNUP_USER_CLEAR,
  }),
  clearUserReset: () => ({
    type: types.RESET_USER_CLEAR,
  }),
  clearUserResetForm: () => ({
    type: globalTypes.UPDATE_STATUS,
    [USER_RESET_REQUEST_KEY]: {
      status: null,
    },
  }),
  clearUserSignupForm: () => ({
    type: globalTypes.UPDATE_STATUS,
    [SIGNUP_REQUEST_KEY]: {
      status: null,
    },
  }),
  createUser: (params: FTCreateUserAction) => ({
    type: types.CREATE_USER,
    ...params,
  }),
  createUserError: ({ errors }: FTCreateUpdateUserException) => ({
    type: types.CREATE_USER_ERROR,
    errors,
  }),
  deleteUser: (userId: string) => ({
    type: types.DELETE_USER,
    userId,
  }),
  loadAllUsers: ({
    customerId,
    siteId,
    pageNumber,
    pageSize,
  }: {
    customerId?: string
    siteId?: string
    pageNumber?: number
    pageSize?: number
  }) => ({
    type: types.LOAD_ALL_USERS,
    customerId,
    siteId,
    pageNumber,
    pageSize,
  }),
  loadUser: (userId: string) => ({
    type: types.LOAD_USER,
    userId,
  }),
  partialUpdateUser: (id: string, body: FTUser) => ({
    type: types.PARTIAL_UPDATE_USER,
    id,
    body,
  }),
  resetUser: ({ email }: { email: string }) => ({
    type: types.RESET_USER,
    email,
  }),
  sendMaWelcomeEmail: ({ email }: { email: string }) => ({
    type: types.MA_SEND_WELCOME_EMAIL,
    email,
  }),
  sendMaResetEmail: ({ email }: { email: string }) => ({
    type: types.MA_SEND_RESET_EMAIL,
    email,
  }),
  sendR1WelcomeEmail: ({ email }: { email: string }) => ({
    type: types.R1_SEND_WELCOME_EMAIL,
    email,
  }),
  sendR1ResetEmail: ({ email }: { email: string }) => ({
    type: types.R1_SEND_RESET_EMAIL,
    email,
  }),
  signupUser: ({
    customerId,
    email,
  }: {
    customerId: string
    email: string
  }) => ({
    type: types.SIGNUP_USER,
    customerId,
    email,
  }),
  updateUser: (params: FTUpdateUserAction) => ({
    type: types.UPDATE_USER,
    ...params,
  }),
  updateUserAssociations: (params: FTUser) => ({
    type: types.UPDATE_USER_ASSOCIATIONS,
    params,
  }),
  updateUserError: ({ errors }: FTCreateUpdateUserException) => ({
    type: types.UPDATE_USER_ERROR,
    errors,
  }),
  getSignupAndResetPasswordToken: (userId: string) => ({
    type: types.SIGNUP_AND_RESET_PASSWORD_TOKEN,
    userId,
  }),
}
export const selectUsers = (state: FTState): Array<FTUser> =>
  state.entities.users.allIds.map((id) => state.entities.users.byId[id])
export const selectUser = (state: FTState, userId: string) =>
  state.entities.users.byId[userId]
export const selectUserListEntity = (state: FTState) => ({
  items: selectUsers(state),
  meta: state.entities.users.meta,
})
export const selectUserListEntityMeta = (state: FTState) =>
  state.entities.users.meta
export const selectUserEntity = (state: FTState, userId: string) => ({
  item: selectUser(state, userId),
  meta: state.entities.users.meta,
})
export const selectSignup = (state: FTState) => state.entities.users.signup
export const selectUserReset = (state: FTState) => state.entities.users.reset
export const selectUserSignUpAndResetPasswordToken = (state: FTState) =>
  state.entities.users.signupAndResetPasswordToken
class API {
  static createUser(params: FTCreateUserAction) {
    const requestData: FTCreateUserRequest = {
      username: params.email,
      ...params,
    }
    return axios
      .post(`${consoleApiUrl()}/admin/users`, requestData, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTUserResponse }) => data)
      .catch(handleAxiosError)
  }

  static createUserMock(params: FTCreateUserAction) {
    return Promise.resolve({ ...params, id: '1234', status: 'PENDING' }).then(
      (data: any) =>
        new Promise((resolve) => {
          setTimeout(() => resolve(data), 1000)
        }),
    )
  }

  static createUserMockError(params: FTCreateUserAction) {
    const mockAxios = axios.create()
    const mock = new MockAdapter(mockAxios, {
      delayResponse: 500,
    })
    const url = `${consoleApiUrl()}/signups`
    mock
      .onPost(url)
      .reply(400, {
        errors: ['Mock error 1', 'Mock error 2'],
      })
      .onAny()
      .passThrough()
    return mockAxios({
      method: 'post',
      url,
      data: params,
      headers: defaultHeaders(),
    })
      .then((data) => data)
      .catch(handleAxiosError)
  }

  static getUser(userId: string | null | undefined = null) {
    const baseUrl = `${consoleApiUrl()}/admin/users`
    const url = userId ? `${baseUrl}/${userId}` : `${baseUrl}?pageSize=100`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => {
        const { data } = response
        const { totalCount } = data
        return { ...data, totalPages: getTotalPages(totalCount, 100) }
      })
      .catch(handleAxiosError)
  }

  static getUserInfo() {
    return axios
      .get(`${consoleApiUrl()}/account`, {
        headers: defaultHeaders(),
      })
      .then((response) => {
        const { customer_id: id, customer_name: name } = response.data
        return {
          customer: {
            id,
            name,
          },
        }
      })
      .catch(handleAxiosError)
  }

  static getUsers(
    customerId: string | null | undefined = null,
    siteId: string | null | undefined = null,
    pageNumber: number | null | undefined = 1,
    pageSize: number = 20,
  ) {
    const query = queryStringify({
      customerId,
      siteId,
      pageNumber,
      pageSize,
    })
    const baseUrl = `${consoleApiUrl()}/admin/users`
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => {
        const { data } = response
        const { totalCount } = data
        return {
          ...data,
          totalPages: getTotalPages(totalCount, pageSize),
          pageNumber,
          pageSize,
        }
      })
      .catch(handleAxiosError)
  }

  static partialUpdateUser(id: string, body: FTUser) {
    return axios
      .patch(
        `${consoleApiUrl()}/admin/users/${id}`,
        {
          id,
          ...body,
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then(() => ({}))
      .catch(handleAxiosError)
  }

  static resetUser(email: string) {
    return axios
      .post(
        `${consoleApiUrl()}/admin/passwordreset`,
        {
          username: email,
          theme: 'REDAPTIVE',
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static sendMaResetEmail(email: string) {
    return axios
      .post(
        `${consoleApiUrl()}/passwordreset/generate-link`,
        {
          email,
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static sendR1ResetEmail(email: string) {
    return axios
      .post(
        `${consoleApiUrl()}/passwordreset`,
        {
          theme: 'DENALI',
          username: email,
        },
        { headers: defaultHeaders() },
      )
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static sendMaWelcomeEmail(email: string) {
    return axios
      .post(
        `${consoleApiUrl()}/user/activate/activation-link`,
        {
          email,
          sendWelcomeEmailMeterApp: true,
        },
        { headers: defaultHeaders() },
      )
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static sendR1WelcomeEmail(email: string) {
    return axios
      .post(
        `${consoleApiUrl()}/user/activate/activation-link`,
        {
          email,
          sendWelcomeEmailR1: true,
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static signupUser(customerId: string, username: string) {
    return axios
      .post(
        `${consoleApiUrl()}/signups`,
        {
          username,
          customerIds: [customerId],
        },
        {
          headers: defaultHeaders(),
        },
      )
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static updateUser(params: FTUpdateUserAction) {
    return axios
      .patch(`${consoleApiUrl()}/admin/users/${params.id}`, params, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTUserResponse }) => data)
      .catch(handleAxiosError)
  }

  static updateUserMock(params: FTUpdateUserAction, user: FTUser) {
    const { status } = params
    const mockFields =
      status ?
        {
          status,
        }
      : {}
    return Promise.resolve({ ...user, ...mockFields }).then(
      (data: FTUserResponse) =>
        new Promise((resolve) => setTimeout(() => resolve(data), 1000)),
    )
  }

  static updateUserMockError(params: FTUpdateUserAction) {
    const mockAxios = axios.create()
    const mock = new MockAdapter(mockAxios, {
      delayResponse: 500,
    })
    const url = `${consoleApiUrl()}/admin/users/${params.id}`
    mock
      .onPatch(url)
      .reply(400, {
        errors: ['Mock error 1', 'Mock error 2'],
      })
      .onAny()
      .passThrough()
    return mockAxios({
      method: 'patch',
      url,
      data: params,
      headers: defaultHeaders(),
    })
      .then((data) => data)
      .catch(handleAxiosError)
  }

  static updateUserAssociations({ params }: { params: FTUser }) {
    return axios
      .post(`${consoleApiUrl()}/admin/user-associations`, params, {
        headers: defaultHeaders(),
      })
      .then(() => ({}))
      .catch(handleAxiosError)
  }

  static getSignupAndResetPasswordToken(userId: string) {
    return axios
      .post(
        `${consoleApiUrl()}/admin/users/${userId}/activation-link`,
        {},
        {
          headers: defaultHeaders(),
        },
      )
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
}

function* partialUpdateUserSaga({
  id,
  body,
}: {
  id: string
  body: FTUser
}): Generator<any, void, any> {
  try {
    yield call(API.partialUpdateUser, id, body)
    yield put({
      type: types.PARTIAL_UPDATE_USER_SUCCESS,
    })
  } catch (error) {
    yield handleSagaError(types.PARTIAL_UPDATE_USER_ERROR, error)
  }
}

function* updateUserAssociationsSaga(payload): Generator<any, void, any> {
  try {
    yield call(API.updateUserAssociations, payload)
    yield put({
      type: types.UPDATE_USER_ASSOCIATIONS_SUCCESS,
    })
  } catch (error) {
    yield handleSagaError(types.UPDATE_USER_ASSOCIATIONS_ERROR, error)
  }
}

function* loadAllUsersSaga({
  customerId,
  siteId,
  pageNumber,
  pageSize,
}: {
  customerId: string
  siteId: string
  pageNumber: number | null | undefined
  pageSize: number | null | undefined
}): Generator<any, void, any> {
  try {
    yield put({
      type: globalTypes.UPDATE_STATUS,
      users: {
        status: statusConstants.LOADING,
      },
    })
    const response = yield call(
      API.getUsers,
      customerId,
      siteId,
      pageNumber,
      pageSize,
    )
    const {
      results,
      next,
      previous,
      totalPages,
      totalCount,
      pageNumber: page,
      pageSize: perPage,
    } = response
    const users: Array<FTUser> = results.map((user) => utils.enhanceUser(user))
    const normalized = normalize(users, [userSchema])
    const payload = {
      ...normalized,
      meta: {
        pageNumber: page,
        pageSize: perPage,
        next,
        previous,
        totalPages,
        totalCount,
      },
    }
    yield put({
      type: types.LOAD_ALL_USERS_SUCCESS,
      payload,
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      users: {
        status: statusConstants.LOADED,
      },
    })
  } catch (e) {
    yield handleError('loadAllUsers', e)
  }
}
function* loadUserSaga({
  userId,
}: {
  userId: string
}): Generator<any, void, any> {
  try {
    yield put({
      type: globalTypes.UPDATE_STATUS,
      users: {
        status: statusConstants.LOADING,
      },
    })
    const response = yield call(API.getUser, userId)
    let { results } = response

    if (userId) {
      results = [response]
    }

    const users: Array<FTUser> = results.map((user) => utils.enhanceUser(user))
    const normalized = normalize(users, [userSchema])
    yield put({
      type: types.LOAD_USER_SUCCESS,
      payload: normalized,
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      users: {
        status: statusConstants.LOADED,
      },
    })
  } catch (e) {
    yield handleError('loadUser', e)
    yield handleSagaError(types.LOAD_USER_ERROR, e)
  }
}
function* createUserSaga(
  params: {
    type: string
  } & FTCreateUserAction,
): Generator<any, void, any> {
  const { type: _, ...request } = params

  try {
    const response: FTUserResponse = yield call(API.createUser, request)
    const user = utils.enhanceUser(response)
    yield put({
      type: types.CREATE_USER_SUCCESS,
      payload: normalize([user], [userSchema]),
    })
  } catch (error) {
    if (error.name && error.name === 'CreateUpdateUserError') {
      yield put(actions.createUserError(error))
    } else {
      yield handleSagaError(types.CREATE_USER_ERROR, error)
    }
  }
}
function* updateUserSaga(
  params: {
    type: string
  } & FTUpdateUserAction,
): Generator<any, void, any> {
  const { type: _, ...request } = params

  try {
    const response: FTUserResponse = yield call(API.updateUser, request)
    const user = utils.enhanceUser(response)
    yield put({
      type: types.UPDATE_USER_SUCCESS,
      payload: normalize([user], [userSchema]),
    })
  } catch (error) {
    if (error.name && error.name === 'createUpdateUserError') {
      yield put(actions.updateUserError(error))
    } else {
      yield handleSagaError(types.UPDATE_USER_ERROR, error)
    }
  }
}
function* signupUserSaga({
  customerId,
  email,
}: {
  customerId: string
  email: string
}): Generator<any, void, any> {
  try {
    yield put({
      type: globalTypes.UPDATE_STATUS,
      [SIGNUP_REQUEST_KEY]: {
        status: statusConstants.LOADING,
      },
    })
    const { signupId } = yield call(API.signupUser, customerId, email)
    yield put({
      type: types.SIGNUP_USER_SUCCESS,
      payload: {
        signupId,
        email,
      },
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      [SIGNUP_REQUEST_KEY]: {
        status: statusConstants.LOADED,
      },
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      [SIGNUP_REQUEST_KEY]: {
        status: null,
      },
    })
  } catch (error) {
    yield handleError(SIGNUP_REQUEST_KEY, error)
  }
}
function* resetUserSaga({
  email,
}: {
  email: string
}): Generator<any, void, any> {
  try {
    yield put({
      type: globalTypes.UPDATE_STATUS,
      [USER_RESET_REQUEST_KEY]: {
        status: statusConstants.LOADING,
      },
    })
    const endpoint = API.resetUser
    const { passwordResetId: resetId } = yield call(endpoint, email)
    yield put({
      type: types.RESET_USER_SUCCESS,
      payload: {
        resetId,
        email,
      },
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      [USER_RESET_REQUEST_KEY]: {
        status: statusConstants.LOADED,
      },
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      [USER_RESET_REQUEST_KEY]: {
        status: null,
      },
    })
  } catch (error) {
    yield handleError(USER_RESET_REQUEST_KEY, error)
  }
}
function* sendMaResetEmailSaga({
  email,
}: {
  email: string
}): Generator<any, void, any> {
  try {
    yield call(API.sendMaResetEmail, email)
    yield put({
      type: types.MA_SEND_RESET_EMAIL_SUCCESS,
      payload: {},
    })
  } catch (error) {
    yield handleError(types.MA_SEND_RESET_EMAIL_ERROR, error)
  }
}

function* sendR1ResetEmailSaga({
  email,
}: {
  email: string
}): Generator<any, void, any> {
  try {
    yield call(API.sendR1ResetEmail, email)
    yield put({ type: types.R1_SEND_RESET_EMAIL_SUCCESS, payload: {} })
  } catch (error) {
    yield handleError(types.R1_SEND_RESET_EMAIL_ERROR, error)
  }
}

function* sendMaWelcomeEmailSaga({
  email,
}: {
  email: string
}): Generator<any, void, any> {
  try {
    yield call(API.sendMaWelcomeEmail, email)
    yield put({
      type: types.MA_SEND_WELCOME_EMAIL_SUCCESS,
      payload: {},
    })
  } catch (error) {
    yield handleError(types.MA_SEND_WELCOME_EMAIL_ERROR, error)
  }
}

function* sendR1WelcomeEmailSaga({
  email,
}: {
  email: string
}): Generator<any, void, any> {
  try {
    yield call(API.sendR1WelcomeEmail, email)
    yield put({ type: types.R1_SEND_WELCOME_EMAIL_SUCCESS, payload: {} })
  } catch (error) {
    yield handleError(types.R1_SEND_WELCOME_EMAIL_ERROR, error)
  }
}

function* fetchUserInfo(): Generator<any, void, any> {
  try {
    yield put({
      type: globalTypes.UPDATE_STATUS,
      user: {
        status: statusConstants.LOADING,
      },
    })
    const userInfo = yield call(API.getUserInfo)
    yield put({
      type: types.FETCH_USER_INFO_SUCCESS,
      payload: userInfo,
    })
    yield put({
      type: globalTypes.UPDATE_STATUS,
      user: {
        status: statusConstants.LOADED,
      },
    })
  } catch (error) {
    yield handleError('userInfo', error)
  }
}

function* getSignupAndResetPasswordTokenSaga({
  userId,
}: {
  userId: string
}): Generator<any, void, any> {
  try {
    const response = yield call(API.getSignupAndResetPasswordToken, userId)

    const users: Array<FTUser> = [response].map((user) =>
      utils.enhanceUser(user),
    )
    const normalized = normalize(users, [userSchema])

    yield put({
      type: types.LOAD_USER_SUCCESS,
      payload: normalized,
    })
    yield put({
      type: types.SIGNUP_AND_RESET_PASSWORD_TOKEN_SUCCESS,
      payload: response.activationOrResetPasswordToken,
    })
  } catch (error) {
    yield handleError(
      types.SIGNUP_AND_RESET_PASSWORD_TOKEN_ERROR,
      error as Error,
    )
  }
}

export function* watchFetchUserInfo(): Generator<any, void, any> {
  yield takeEvery(types.FETCH_USER_INFO, fetchUserInfo)
}

export const sagas = [
  takeEvery(types.CREATE_USER, createUserSaga),
  takeEvery(types.UPDATE_USER, updateUserSaga),
  takeEvery(types.SIGNUP_USER, signupUserSaga),
  takeEvery(types.RESET_USER, resetUserSaga),
  takeEvery(types.MA_SEND_RESET_EMAIL, sendMaResetEmailSaga),
  takeEvery(types.MA_SEND_WELCOME_EMAIL, sendMaWelcomeEmailSaga),
  takeEvery(types.R1_SEND_RESET_EMAIL, sendR1ResetEmailSaga),
  takeEvery(types.R1_SEND_WELCOME_EMAIL, sendR1WelcomeEmailSaga),
  takeEvery(types.LOAD_ALL_USERS, loadAllUsersSaga),
  takeEvery(types.LOAD_USER, loadUserSaga),
  takeEvery(types.PARTIAL_UPDATE_USER, partialUpdateUserSaga),
  takeEvery(types.UPDATE_USER_ASSOCIATIONS, updateUserAssociationsSaga),
  takeLatest(
    types.SIGNUP_AND_RESET_PASSWORD_TOKEN,
    getSignupAndResetPasswordTokenSaga,
  ),
]
