import axios from 'axios'
import FileSaver from 'file-saver'
import moment from 'moment-timezone'
import { normalize, schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { consts as meterConsts, utils as meterUtils } from './meters'
import { makeActionTypes, renderTimestamp } from './utils'
import { consoleApiUrl, defaultHeaders } from '../api'
import { handleAxiosError } from '../api/utils'
import * as mainTypes from '../constants/actionTypes'
import * as status from '../constants/status'
import { handleError, handleSagaError } from '../sagas/utils'

type FTFetchRTMeterStatusAction = {
  ids: Array<string>
}
type FTMeta = {
  error: string | null | undefined
  loading: boolean
  submission: Array<string>
  success: boolean
  totalPages: number
  totalCount: number
  pageSize: number
  pageNumber: number
  next: string | null | undefined
  previous: string | null | undefined
  error?: string
  orderBy?: {
    sort: 'ASC' | 'DESC' | ''
    field: string
  }
  timestamp: moment | null | undefined
}
export type FTMeterStatus = {
  /* If the metadata process has not run yet, id and firstReportDate can be null
  while still being a valid meter. */
  id: string | null | undefined
  macAddress: string
  type: 'REDAPTIVE' | 'REDAPTIVE_GAS' | 'ENERTIV' | 'LEVITON' | 'UNKNOWN'
  onlineStatus: boolean
  firstReportDate: string | null | undefined
  lastReportDate: string | null | undefined
  panelNames?: Array<string>
  // Enhanced properties
  exists: boolean
  meterType: string
  meterStatus: 'Online' | 'Offline'
  lastMeasurementDate: string
  mostRecentReported: string
  firstReported: string
}
type FTRTMeterStatusEntity = {
  byId: Record<string, FTMeterStatus>
  allIds: Array<string>
  meta: FTMeta
}
type FTState = {
  meterStatus: FTRTMeterStatusEntity
}
export type FTRTMeterStatusListEntity = {
  items: Array<Record<string, any>>
  meta: Record<string, any>
}
// Utils
export const enhanceMeterStatus = (meterStatus: FTMeterStatus) => {
  if (!meterStatus) {
    return undefined
  }

  const {
    firstReportDate,
    id,
    lastMeasurementDate,
    lastReportDate,
    macAddress,
    onlineStatus,
    type,
  } = meterStatus
  return {
    ...meterStatus,
    id: id || macAddress,
    exists: type && type.toUpperCase() !== meterConsts.METER_TYPE_UNKNOWN,
    meterType: meterUtils.sourceToMeterType(type, 'Unknown'),
    meterStatus: meterUtils.getMeterStatus(onlineStatus),
    lastMeasurementDate: renderTimestamp(
      lastMeasurementDate,
      moment.tz.guess(),
    ),
    originalLastMeasurementDate: lastMeasurementDate || 'Unavailable',
    mostRecentReported: renderTimestamp(lastReportDate, moment.tz.guess()),
    firstReported: renderTimestamp(firstReportDate, moment.tz.guess()),
  }
}
// Action Types
export const types = {
  ...makeActionTypes('GET_INSTALLED_METERS'),
  ...makeActionTypes('GET_METER_HISTORY'),
  ...makeActionTypes('GET_RT_METER_STATUS'),
  RESET_RT_FORM: 'RESET_RT_FORM',
  RESET_RT_SUBMISSION: 'RESET_RT_SUBMISSION',
}
// Reducers
export const initialState = {
  byId: {},
  allIds: [],
  meta: {
    pageNumber: 1,
    pageSize: 20,
    next: null,
    previous: null,
    orderBy: null,
    loading: false,
    success: false,
    error: null,
    submission: [],
  },
}
const entity = 'rtMeters'
export const entitySchema = new schema.Entity(entity)

function entityById(action) {
  return { ...action.payload.entities[entity] }
}

function entityAllIds(action) {
  return [...new Set(action.payload.result)]
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.GET_RT_METER_STATUS:
      return state

    case types.GET_RT_METER_STATUS_SUCCESS:
      return entityById(action)

    case types.RESET_RT_FORM:
      return initialState.byId

    default:
      return state
  }
}

function allIds(state = initialState.allIds, action) {
  switch (action.type) {
    case types.GET_RT_METER_STATUS:
      return state

    case types.GET_RT_METER_STATUS_SUCCESS:
      return entityAllIds(action)

    case types.RESET_RT_FORM:
      return initialState.allIds

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.GET_RT_METER_STATUS:
      return {
        ...state,
        loading: true,
        error: null,
        submission: action.params.ids,
      }

    case types.GET_RT_METER_STATUS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.GET_RT_METER_STATUS_SUCCESS:
      return {
        ...state,
        ...action.payload.meta,
        loading: false,
        error: null,
        success: true,
        timestamp: moment().format('h:mm:ss A'),
      }

    case types.RESET_RT_SUBMISSION:
      return { ...state, success: false, timestamp: null }

    case types.RESET_RT_FORM:
      return initialState.meta

    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  meta,
}) // Action Creators

export const actions = {
  getRealTimeMeterStatus: (params: FTFetchRTMeterStatusAction) => ({
    type: types.GET_RT_METER_STATUS,
    params,
  }),
  // resetRTForm will clear the entire form, used when leaving the page
  resetRTForm: () => ({
    type: types.RESET_RT_FORM,
  }),

  /* resetRTSubmission prepares the state for the user to resubmit the form, possibly
  with adjustments to the current list of MAC addresses */
  resetRTSubmission: () => ({
    type: types.RESET_RT_SUBMISSION,
  }),
  getInstalledMeters: () => ({
    type: types.GET_INSTALLED_METERS,
  }),
  getMeterHistory: () => ({
    type: types.GET_METER_HISTORY,
  }),
}
// Selectors
export const selectRTMeterList = (state: FTState): Array<FTMeterStatus> =>
  state.meterStatus.allIds.map((id) => state.meterStatus.byId[id])

export const selectRTMeterById = (state: FTState): Object =>
  state.meterStatus.byId

export const selectRTMeter = (state: FTState, id: string): FTMeterStatus =>
  state.meterStatus.byId[id]

export const selectRTMeterListEntity = (state: FTState) => ({
  items: selectRTMeterList(state),
  meta: state.meterStatus.meta,
})
export const selectRTMeterEntity = (state: FTState, id: string) => ({
  item: selectRTMeter(state, id),
  meta: state.meterStatus.meta,
})
// API
export class API {
  static fetchRTMeterStatus({ ids }: FTFetchRTMeterStatusAction) {
    const baseUrl = `${consoleApiUrl()}/meter/status/last-report-date`
    const query = ids
      .reduce((str, cur) => `${str}${cur},`, 'macAddresses=')
      .slice(0, -1)
    const url = `${baseUrl}?${query}`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then((response) => {
        const { data } = response
        return {
          results: data,
          totalPages: 1,
          pageNumber: 1,
          pageSize: 10 ** 4,
        }
      })
      .catch(handleAxiosError)
  }

  static getInstalledMeters() {
    return axios
      .get(`${consoleApiUrl()}/meter/status`, {
        responseType: 'blob',
        headers: defaultHeaders(),
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'status.csv')
      })
      .catch(handleAxiosError)
  }

  static getMeterHistory() {
    return axios
      .get(`${consoleApiUrl()}/reports/meters/online`, {
        responseType: 'blob',
        headers: defaultHeaders(),
      })
      .then((response) => {
        FileSaver.saveAs(response.data, 'meter-online-history.csv')
      })
      .catch(handleAxiosError)
  }
}

// Sagas
function* getRealTimeMeterStatusSaga({
  params,
}: {
  params: FTFetchRTMeterStatusAction
}): Generator<any, void, any> {
  try {
    yield delay(500)
    const response = yield call(API.fetchRTMeterStatus, {
      ...params,
      ids: params.ids.filter((i) => i),
    })
    const { results } = response
    const enhanced = results.map(enhanceMeterStatus)
    const payload = normalize(enhanced, [entitySchema])
    yield put({
      type: types.GET_RT_METER_STATUS_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.GET_RT_METER_STATUS_ERROR, e)
  }
}

function* getInstalledMetersSaga(): Generator<any, void, any> {
  try {
    yield put({
      type: mainTypes.UPDATE_STATUS,
      installedStatus: {
        status: status.LOADING,
      },
    })
    yield call(API.getInstalledMeters)
    yield put({
      type: mainTypes.UPDATE_STATUS,
      installedStatus: {
        status: status.LOADED,
        error: null,
      },
    })
  } catch (e) {
    yield handleError('installedStatus', e)
  }
}

function* getMeterHistorySaga(): Generator<any, void, any> {
  try {
    yield put({
      type: mainTypes.UPDATE_STATUS,
      historyStatus: {
        status: status.LOADING,
      },
    })
    yield call(API.getMeterHistory)
    yield put({
      type: mainTypes.UPDATE_STATUS,
      historyStatus: {
        status: status.LOADED,
        error: null,
      },
    })
  } catch (e) {
    yield handleError('historyStatus', e)
  }
}

export const sagas = [
  takeLatest(types.GET_RT_METER_STATUS, getRealTimeMeterStatusSaga),
  takeEvery(types.GET_INSTALLED_METERS, getInstalledMetersSaga),
  takeEvery(types.GET_METER_HISTORY, getMeterHistorySaga),
]
