import { PureComponent, ReactNode } from 'react'
import Select, { components } from 'react-select'
import styled from 'styled-components'

import * as consts from '../constants'

type FTOnChangePayloadItem = {
  value: string
  label: string
}
export type FTOnChangePayload = FTOnChangePayloadItem
type FTOnChangePayloadMulti = Array<FTOnChangePayloadItem>
export type FTUpdateValueFunc = (arg0: FTOnChangePayload) => void
export type FTUpdateValueFuncMulti = (arg0: FTOnChangePayloadMulti) => void
const Component = styled.div`
  position: relative;
`
const MenuFooter = styled.div`
  &:hover {
    color: #337ab7;
  }

  background-color: #fff;
  position: absolute;
  top: ${({ directionUp }) => (directionUp ? '-40px' : 'unset')};
  bottom: ${({ directionUp }) => (directionUp ? 'unset' : '-40px')};
  left: -1px;
  width: calc(100% + 2px);
  height: 40px;
  line-height: 40px;

  font-size: 14px;
  padding-left: 9px;
  font-family: 'Avenir Next';
  color: #4a4a4a;
  cursor: pointer;

  border: 1px solid #ccc;
  border-top-color: ${({ directionUp }) => (directionUp ? '#ccc' : '#e6e6e6')};

  border-bottom-left-radius: ${({ directionUp }) =>
    directionUp ? '0px' : '3px'};
  border-bottom-right-radius: ${({ directionUp }) =>
    directionUp ? '0px' : '3px'};
  border-top-left-radius: ${({ directionUp }) => (directionUp ? '3px' : '0px')};
  border-top-right-radius: ${({ directionUp }) =>
    directionUp ? '3px' : '0px'};
`

const getSelectControlBorderColor = ({ hiddenMode, showRequired }) => {
  if (showRequired) {
    return '#D0021B'
  }

  return hiddenMode ? 'transparent' : '#d9d9d9 #ccc #b3b3b3'
}

export const SelectStyles = styled.div`
  height: 36px;
  cursor: pointer;
  .Select {
    color: #4a4a4a;
    cursor: pointer;
    font-size: 14px;
    height: 36px;
    line-height: 20px;
    width: 100%;
  }

  &:hover {
    .Select-arrow {
      border-color: #4a4a4a transparent transparent;
    }
  }

  .Select-arrow {
    border-color: ${({ hiddenMode }) =>
      hiddenMode ? 'transparent' : '#4a4a4a transparent transparent'};
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    position: relative;
  }

  .Select-arrow-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 20px;
    padding-right: 5px;
  }

  .Select__control {
    &.active {
      border-color: #337ab7;
    }
    min-height: ${({ customDropdown }) => (customDropdown ? '50px' : '36px')};
  }

  .Select .Select__control {
    background-color: ${({ hiddenMode, disabled }) => {
      if (hiddenMode) return 'transparent'
      if (disabled) return '#f0f0f0'
      return '#fff'
    }};
    display: ${({ inlineFormMode }) => inlineFormMode && 'inline-table'};
    border-color: ${getSelectControlBorderColor};
    top: ${({ inlineFormMode }) => inlineFormMode && '-2px'};
  }

  .Select .Select__control--is-focused,
  &:hover .Select .Select__control {
    border-color: #d9d9d9 #ccc #b3b3b3;
    box-shadow: none;
    background-color: ${({ disabled }) => !disabled && '#fff'};
  }

  .Select__control--menu-is-open {
    border: 1px solid #337ab7;
    border-radius: ${({ directionUp }) =>
      directionUp ? '0 0 3px 3px' : '3px 3px 0 0'};

    .Select-arrow-zone .Select-arrow {
      border-color: transparent transparent #4a4a4a;
      border-width: 0 5px 5px;
      top: -2px;
    }
  }

  .Select__control--is-focused .Select-arrow {
    border-color: #4a4a4a transparent transparent;
  }

  .Select__indicator {
    border-color: #4a4a4a transparent transparent;
    color: transparent;
    padding: 0;
  }

  .Select__option {
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
  }

  .Select_option {
    align-items: center;
    display: flex;
    height: 50px;
    width: 100%;
  }

  .icon {
    margin-right: 10px;
    margin-left: 10px;
  }

  .format_icon {
    margin-right: 10px;
    padding-top: 5px;
  }

  .label {
    margin-right: 10px;
    margin-left: 10px;
  }

  .Select_option:hover {
    background-color: #f6f6f6;
    border: 1px solid #ccc;
  }

  .Select__menu {
    box-shadow: none;
    margin-bottom: 0;
    margin-top: 0;
    max-height: 336px;
    position: relative;
    z-index: 1;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      margin-bottom: 3px;
      margin-top: 0;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      background-clip: content-box;
      border: 2px transparent solid;
      border-radius: 10px;
      width: 7px;
    }

    > .Select__menu {
      border: 1px solid #ccc;
      border-radius: ${({ directionUp }) =>
        directionUp ? '3px 3px 0 0' : '0 0 3px 3px'};
      border-bottom-width: ${({ directionUp }) => (directionUp ? '0' : '1px')};
      border-top-width: ${({ directionUp }) => (directionUp ? '1px' : '0')};
    }

    .Select__option {
      border-bottom: 1px solid transparent;
      border-top: 1px solid transparent;
      box-sizing: border-box;
      height: 42px;

      &.Select__option--is-focused {
        border-bottom: 1px solid #ccc;
        border-top: 1px solid #ccc;
      }
    }
  }

  .Select__menu-list {
    overflow-y: auto;
    padding-top: 0;
    padding-bottom: 0;
  }

  .Select__menu-outer {
    bottom: ${({ directionUp }) => (directionUp ? '100%' : '')};
    max-height: 338px;
    position: absolute;
    top: ${({ directionUp }) => (directionUp ? 'auto' : '')};
    width: 100%;
  }

  .Select__option--is-focused,
  .Select__option--is-selected {
    background-color: #efefef;
    color: #4a4a4a;
  }

  .Select__placeholder {
    color: #4a4a4a;
    height: 36px;
    line-height: 36px;
  }

  .Select__value-container {
    padding-bottom: 0;
    padding-top: 0;
  }
`

/* a red dot to indicate edited status */
const Marker = styled.div`
  display: ${({ edited }) => (edited ? 'block' : 'none')};
  ${SelectStyles}:focus + &,
  ${SelectStyles}:hover + & {
    display: ${({ hideOnHover }) => hideOnHover && 'none'};
  }

  position: absolute;
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  top: ${({ top }) => top || '14px'};
  bottom: ${({ bottom }) => bottom};
  width: 6px;
  height: 6px;
  background-color: ${({ color }) => color};
  border-radius: 10px;
`

/* an entry in the dropdown list */
export type FTItem = {
  id: string | number
  name: string
  icon?: ReactNode
  renderFunc?: () => ReactNode
}
type FTProps = {
  /* The items to render in the dropdown */
  items: Array<FTItem>

  /* react-select props:
  https://github.com/JedWatson/react-select/tree/82910d9225f7aff73ac5052da9dc1e7f52f13de7#select-props */
  disabled?: boolean
  fieldName?: string
  isMulti?: boolean
  searchable?: boolean
  tabIndex?: string

  /* The currently selected item in the dropdown. */
  // $FlowFixMe
  selectedItem?: FTItem | null | undefined
  selectedItems?: Array<FTItem>

  /* Does not render the dropdown and instead only renders its current value */
  isReadOnly?: boolean

  /* The text the component label when the current value is not set */

  /* Label displayed with unset value when dropdown not open, i.e.
  -- Assign a Panel -- */
  notSetLabelText?: string

  /* The text of unset item in dropdown list, i.e. 'Unassigned'
  required unsettable = true */
  notSetItemText?: string
  notSetItemRenderFunc?: null | (() => ReactNode)

  /* The value of the unset item in the dropdown list. Defaults to null */
  notSetItemValue?: string | null | undefined

  /* Callback to invoke when user selects a value */
  updateValue?: FTUpdateValueFunc
  updateValueMulti?: FTUpdateValueFuncMulti

  /* User has the option to clear the selected item, i.e. no entry or null */
  unsettable?: boolean

  /* Makes the dropdown appear as plaintext until interaction, at which point
  the border and dropdown arrow render */
  hiddenMode?: boolean

  /* indicates the field has been modified by rendering a colored dot next to it */
  edited?: boolean
  editDotColor?: string
  hideEditDotOnHover?: boolean

  /* Enables the positioning of the edited dot marker */
  editDotBottom?: string
  editDotLeft?: string
  editDotRight?: string
  editDotTop?: string

  /* causes the dropdown to render above the field rather than the default below */
  directionUp?: boolean

  /* when rendered inline next to other fields, the Select component has an offset
  of several pixels that makes it appear out of line. This flag fixes the styles
  so that does not occur
  Example: State dropdown on SiteForm */
  inlineFormMode?: boolean

  /* Outlines the field in red when true */
  showRequired?: boolean

  /* Adds a footer to the dropdown which invokes the provided callback on click */
  onFooterClick?: (...args: Array<any>) => any

  /* The text of the footer link - requires onFooterClick to be defined */
  footerLinkText?: string

  /* Allow the component to be wrapped in a styled-component */
  className?: string

  /* Index can be used to optimize event handlers */
  index?: number

  /* Whether to block scroll events when the menu is open */
  menuShouldBlockScroll?: boolean
  menuPortalTarget?: boolean
  customDropdown?: boolean
}
type FTOption = {
  value: string | null | undefined
  label: ReactNode | null | undefined
  icon?: ReactNode
}
type FTState = {
  options: Array<FTOption>
}
export default class ListSelector extends PureComponent<FTProps, FTState> {
  static defaultProps = {
    className: '',
    directionUp: false,
    disabled: false,
    editDotColor: '#c70d08',
    editDotLeft: '',
    editDotRight: '',
    editDotBottom: '',
    editDotTop: '',
    edited: false,
    fieldName: '',
    footerLinkText: '',
    isMulti: false,
    hiddenMode: false,
    hideEditDotOnHover: true,
    index: 0,
    inlineFormMode: false,
    isReadOnly: false,
    notSetItemRenderFunc: null,
    notSetItemText: 'Unassigned',
    notSetItemValue: null,
    notSetLabelText: '',
    onFooterClick: undefined,
    searchable: true,
    selectedItem: null,
    selectedItems: [],
    showRequired: false,
    tabIndex: '',
    updateValue: () => {},
    updateValueMulti: () => {},
    unsettable: true,
    menuShouldBlockScroll: false,
    menuPortalTarget: false,
    customDropdown: false,
  }

  constructor(props: FTProps) {
    super(props)
    this.state = {
      options: this.getOptions(props),
    }
  }

  componentDidUpdate(prevProps: FTProps) {
    const {
      items,
      notSetItemRenderFunc,
      notSetItemText,
      notSetItemValue,
      unsettable,
    } = this.props
    const {
      items: itemsPrev,
      notSetItemRenderFunc: notSetItemRenderFuncPrev,
      notSetItemText: notSetItemTextPrev,
      notSetItemValue: notSetItemValuePrev,
      unsettable: unsettablePrev,
    } = prevProps

    if (
      items !== itemsPrev ||
      notSetItemRenderFunc !== notSetItemRenderFuncPrev ||
      notSetItemText !== notSetItemTextPrev ||
      notSetItemValue !== notSetItemValuePrev ||
      unsettable !== unsettablePrev
    ) {
      this.setState({
        options: this.getOptions(this.props),
      })
    }
  }

  getOptions = (props: FTProps) => {
    const {
      items,
      notSetItemValue,
      notSetItemRenderFunc,
      notSetItemText,
      unsettable,
    } = props
    let options = items.map<FTOption>(({ id, name, icon, renderFunc }) => ({
      value: id,
      label: renderFunc ? renderFunc() : name,
      icon,
    }))

    if (unsettable) {
      options = [
        {
          value: notSetItemValue,
          label: notSetItemRenderFunc ? notSetItemRenderFunc() : notSetItemText,
        },
        ...options,
      ]
    }

    return options
  }

  CustomOption = (props: any) => (
    <div className='Select_option' {...props.innerProps}>
      <span className='icon'>{props.data.icon}</span>
      <span className='label'>{props.label}</span>
    </div>
  )

  renderMenu = (params: any) => (
    <div className='Select__menu-outer'>
      <div className='Select__menu' role='listbox'>
        <components.Menu {...params}>{params.children}</components.Menu>
      </div>
    </div>
  )

  formatOptionLabel = ({ label, icon }: any) => (
    <div className='Select_option'>
      <span className='format_icon'>{icon}</span>
      <span className='label'>{label}</span>
    </div>
  )

  renderMenuList = (params: any) => (
    <>
      <components.MenuList {...params}>{params.children}</components.MenuList>

      {this.props.onFooterClick && this.props.footerLinkText && (
        <MenuFooter
          data-index={this.props.index}
          directionUp={this.props.directionUp}
          onClick={this.props.onFooterClick}
        >
          {this.props.footerLinkText}
        </MenuFooter>
      )}
    </>
  )

  renderDropdownIndicator = (params: any) => (
    <components.DropdownIndicator {...params}>
      <span className='Select-arrow-zone'>
        <span className='Select-arrow' />
      </span>
    </components.DropdownIndicator>
  )

  renderIndicatorSeparator = () => ''

  getSelectedOptions = () => {
    const { isMulti, selectedItem, selectedItems } = this.props
    const { options } = this.state

    if (isMulti) {
      return selectedItems ?
          selectedItems.map<FTOption | null | undefined>((item) =>
            options.find(({ value }) => item.id === value),
          )
        : []
    }

    return (
      (selectedItem &&
        options.find(({ value }) => selectedItem.id === value)) ||
      null
    )
  }

  getSelectedLabel = () => {
    const { selectedItem, selectedItems, isMulti } = this.props

    if (isMulti) {
      return selectedItems && selectedItems.length ?
          selectedItems.map((item) => item.name).join(', ')
        : consts.NOT_SET
    }

    return selectedItem ? selectedItem.name : consts.NOT_SET
  }

  styles = {
    multiValueLabel: (styles: Record<string, any>) => ({
      ...styles,
      fontSize: '14px',
    }),
    multiValueRemove: (styles: Record<string, any>) => ({
      ...styles,
      ':hover': {
        backgroundColor: '#f4f4f4',
        cursor: 'pointer',
      },
    }),
  }

  render() {
    const {
      className,
      directionUp,
      disabled,
      editDotBottom,
      editDotColor,
      editDotLeft,
      editDotRight,
      editDotTop,
      edited,
      fieldName,
      hiddenMode,
      hideEditDotOnHover,
      inlineFormMode,
      isMulti,
      isReadOnly,
      notSetLabelText,
      searchable,
      selectedItem,
      showRequired,
      tabIndex,
      updateValue,
      updateValueMulti,
      menuShouldBlockScroll,
      menuPortalTarget,
      customDropdown,
    } = this.props
    const { options } = this.state
    const placeholder =
      selectedItem ?
        (selectedItem.renderFunc && selectedItem.renderFunc()) ||
        selectedItem.name
      : notSetLabelText
    const value = this.getSelectedOptions()
    const label = this.getSelectedLabel()
    const customComponent = {
      DropdownIndicator: this.renderDropdownIndicator,
      IndicatorSeparator: this.renderIndicatorSeparator,
      Menu: this.renderMenu,
      MenuList: this.renderMenuList,
    }
    return (
      <Component className={className}>
        {isReadOnly && <div>{label}</div>}
        {!isReadOnly && (
          <>
            <SelectStyles
              customDropdown={customDropdown}
              className='ListSelector-Dropdown--wrapper'
              hiddenMode={hiddenMode}
              directionUp={directionUp}
              inlineFormMode={inlineFormMode}
              disabled={disabled}
              showRequired={showRequired}
            >
              <Select
                backspaceRemovesValue={false}
                className='ListSelector-Dropdown Select'
                classNamePrefix='Select'
                components={
                  customDropdown ?
                    { ...customComponent, Option: this.CustomOption }
                  : customComponent
                }
                isClearable={false}
                isDisabled={disabled}
                isMulti={isMulti}
                isSearchable={searchable}
                name={fieldName}
                noResultsText='None'
                onChange={isMulti ? updateValueMulti : updateValue}
                options={options}
                placeholder={placeholder}
                styles={this.styles}
                tabIndex={tabIndex}
                value={value}
                formatOptionLabel={
                  customDropdown ? this.formatOptionLabel : null
                }
                menuShouldBlockScroll={menuShouldBlockScroll}
                menuPortalTarget={menuPortalTarget ? document.body : null}
                menuPlacement={menuPortalTarget ? 'auto' : undefined}
              />
            </SelectStyles>
            <Marker
              bottom={editDotBottom}
              color={editDotColor}
              edited={edited}
              hideOnHover={hideEditDotOnHover}
              left={editDotLeft}
              right={editDotRight}
              top={editDotTop}
            />
          </>
        )}
      </Component>
    )
  }
}
