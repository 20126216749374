import { useCallback, useState } from 'react'

import FileUploadModal from './FileUploadModal'
import Button from '../../../components/Button2'
import UploadIcon from '../../../components/Icons/svg/UploadIcon'
import PageHeader from '../../../components/PageHeader'
import useRequirePermission from '../../../hooks/useRequirePermission'
import { FTWithRouter } from '../../../types'
import { GROUPS } from '../../../utils/constants'
import {
  HeaderStyled,
  IconContainer,
  ISRFileUploadSectionContainer,
  ISRProcessorPageStyled,
} from '../BillingThisMonth/styles'

type FTProps = FTWithRouter

const ISRFileProcessorIndex = (props: FTProps) => {
  const { history } = props
  const handleBackNavigation = useCallback(() => {
    history.push('/billing')
  }, [history])
  const [showFileUploadModal, setShowFileUploadModal] = useState(false)
  return (
    <>
      <ISRProcessorPageStyled>
        <HeaderStyled>
          <PageHeader
            Title='ISR File Processor'
            handleBackNavigation={handleBackNavigation}
          />
        </HeaderStyled>
        <ISRFileUploadSectionContainer>
          <Button
            type='outlined'
            onClick={() => {
              setShowFileUploadModal(true)
            }}
            disabled={false}
          >
            <IconContainer>
              <UploadIcon height={16} width={16} />
            </IconContainer>
            &nbsp; Upload ISR
          </Button>
        </ISRFileUploadSectionContainer>
      </ISRProcessorPageStyled>
      <FileUploadModal
        showDilog={showFileUploadModal}
        setShowDialog={setShowFileUploadModal}
      />
    </>
  )
}

export default ISRFileProcessorIndex
