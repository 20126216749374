import classNames from 'classnames'
import { Component, ReactNode } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import styled from 'styled-components'

import Button2 from './Button2'
import Spinner, { SpinnerStyles } from './Spinner'
import { actions as modalActions } from '../ducks/modal'

type FTRenderFunc = () => ReactNode
type FTProps = {
  acceptText?: string | null | undefined
  actions: Record<string, unknown>
  content?: Array<string>
  declineText?: string
  hideClose?: boolean
  isLoading?: boolean
  modalWidth?: string
  onAccept?: (args: unknown) => void
  onClose?: (args: unknown) => void
  onDecline?: (args: unknown) => void
  renderBody?: FTRenderFunc | null | undefined
  renderContent?: FTRenderFunc | null | undefined
  small?: boolean
  title?: string
}
const HeaderStyles = styled.div``
const LoadingStyles = styled.div``
const TitleStyles = styled.div``
const CloseStyles = styled.div``
export const ModalContentStyles = styled.div``
const ModalButtonsStyles = styled.div``
const AcceptStyles = styled(Button2)``
const DeclineStyles = styled(Button2)``

const ModalStyles = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(47, 50, 53, 0.5);
  z-index: 99999;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  font-size: 14px;
  overflow: auto;

  ${ModalButtonsStyles} {
    text-align: center;
    text-transform: uppercase;
    user-select: none;

    > * {
      border-radius: 4px;
      margin: auto;
      width: 145px;
      height: 36px;
      line-height: 42px;
      display: inline-block;
      cursor: pointer;

      text-shadow: 0 1px 0 0 #ffffff;
      font-weight: 600;
      font-size: 14px;
      text-align: center;

      &:not(:first-child) {
        margin-left: 40px;
      }
    }
  }

  ${ModalContentStyles} {
    padding: 15px 0 25px 0;
    line-height: 1.43;

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ${HeaderStyles} + ${ModalButtonsStyles} {
    margin-top: 40px;
  }

  ${LoadingStyles} {
    display: inline-block;
    margin-right: 10px;

    ${SpinnerStyles}.tiny {
      top: 6px;
    }
  }
`
const ModalBoxStyled = styled.div<{
  modalWidth?: string
  small?: boolean
}>`
  position: relative;
  margin: 10% auto;
  border-radius: 4px;
  background: #fff;
  padding: 30px 40px;
  box-sizing: border-box;
  width: ${({ modalWidth, small }) =>
    (small && '334px') || (modalWidth && modalWidth)};

  ${HeaderStyles} {
    ${TitleStyles} {
      font-weight: 600;
      line-height: 20px;
      font-size: 14px;
      color: #4a4a4a;
      display: inline-block;
    }

    ${CloseStyles} {
      font-size: 19px;
      color: #464a54;
      float: right;
      text-decoration: none;
      cursor: pointer;
      margin-top: -5px;
    }
  }
`

class CustomComponent extends Component<FTProps & PropsFromRedux> {
  static defaultProps: FTProps & PropsFromRedux = {
    acceptText: null,
    content: [],
    declineText: '',
    hideClose: false,
    isLoading: false,
    modalWidth: undefined,
    onAccept: undefined,
    onClose: undefined,
    onDecline: undefined,
    renderBody: null,
    renderContent: null,
    small: false,
    title: '',
    actions: {} as typeof modalActions,
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.escapeFunction, false)
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.escapeFunction, false)
  }

  escapeFunction = (event: KeyboardEvent) => {
    const { actions } = this.props

    if (event.keyCode === 27) {
      actions.hideModal()
    }
  }

  render() {
    const {
      acceptText,
      content = [],
      declineText,
      hideClose = false,
      isLoading,
      modalWidth,
      onAccept,
      onClose,
      onDecline,
      renderBody,
      renderContent,
      small,
      title,
    } = this.props
    return (
      <ModalStyles className='Modal'>
        <ModalBoxStyled
          modalWidth={modalWidth}
          small={small}
          className={classNames({
            box: true,
            small,
          })}
        >
          {!renderBody && (
            <>
              <HeaderStyles className='header'>
                {isLoading && (
                  <LoadingStyles className='Modal-loading'>
                    <Spinner size='tiny' />
                  </LoadingStyles>
                )}

                {title && <TitleStyles className='title'>{title}</TitleStyles>}

                {onClose && !hideClose && (
                  <CloseStyles className='close' onClick={onClose}>
                    <span className='ion-android-close' />
                  </CloseStyles>
                )}
              </HeaderStyles>

              {content.length > 0 && (
                <ModalContentStyles className='content'>
                  {content.map((text) => (
                    <p key={text}>{text}</p>
                  ))}
                </ModalContentStyles>
              )}

              {renderContent && renderContent()}
            </>
          )}

          {renderBody && renderBody()}

          {(acceptText || declineText) && (
            <ModalButtonsStyles className='buttons'>
              {declineText && (
                <DeclineStyles
                  className='decline'
                  onClick={onDecline}
                  type='transparent'
                >
                  {declineText}
                </DeclineStyles>
              )}
              {acceptText && (
                <AcceptStyles
                  className='accept'
                  onClick={onAccept}
                  type='secondary'
                >
                  {acceptText}
                </AcceptStyles>
              )}
            </ModalButtonsStyles>
          )}
        </ModalBoxStyled>
      </ModalStyles>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(modalActions, dispatch),
})

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CustomComponent)
