import { capitalCase } from 'capital-case'
import moment from 'moment-timezone'
import numeral from 'numeral'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import ActionPaneView from './ActionPaneView'
import AllPanelsTable from './AllPanelsTable'
import { getDashboardUrl } from '../api'
import features from '../authorization/features'
import * as requestStatusStates from '../constants/status'
import { selectors as authSelectors } from '../ducks/auth'
import {
  actions as customerActions,
  selectCustomerEntity,
} from '../ducks/customers'
import {
  actions as energyStarIntegrationActions,
  energyStarSubmissionsEntity,
} from '../ducks/energyStarIntegration'
import {
  actions as meterActions,
  selectMeterListWithHealthEntity,
} from '../ducks/meters'
import { METER_RESOURCE_TYPES } from '../ducks/meters/generation'
import {
  actions as meterStatusActions,
  selectRTMeterById,
} from '../ducks/meterStatus'
import { actions as modalActions } from '../ducks/modal'
import { actions as panelActions, selectPanelListEntity } from '../ducks/panels'
import {
  actions as siteActions,
  CONTRACT_TYPES,
  isRedaptiveMeter,
  naturallySortContracts,
  selectSiteEntity,
  selectSiteExternalResources,
  selectSiteHealth,
  selectSiteHealthRequestStatus,
  themes,
} from '../ducks/sites'
import {
  actions as userSummaryActions,
  getUserSummaryListEntity,
} from '../ducks/userSummaries'
import { renderTimestamp } from '../ducks/utils'
import '../types'
import type { FTRequestStatus, FTRouterLocation } from '../types'
import { formatHealthStatus, isValueSet, renderHealthStatus } from '../utils'
import Breadcrumbs from './Breadcrumbs'
import DeprecatedEntityListPane from './DeprecatedEntityListPane'
import ESSiteStatus from './ESSiteStatus/ESSiteStatus'
import NotVerifiedIcon from './Icons/NotVerifiedIcon'
import VerifiedIcon from './Icons/VerifiedIcon'
import LoadingSpinner from './LoadingSpinner'
import RequestStateFieldLabel from './RequestStateFieldLabel'
import StatusIconItem from './StatusIconItem'
import StyledLink from './StyledLink'
import TabPane from './Tabs/TabPane'
import Title from './Title'
import UsersTable from './UsersTable'
import VerticalTable from './VerticalTable'
import { Label } from './VerticalTable/Base'

type FTProps = FTRouterLocation & {
  siteHealthRequestStatus: FTRequestStatus
}
const Styles = styled.div`
  ${Label} {
    min-width: 325px;
  }
`
const InlineVerifiedIcon = styled(VerifiedIcon)`
  font-size: 16px;
  vertical-align: text-top;
`
const InlineNotVerifiedIcon = styled(NotVerifiedIcon)`
  font-size: 16px;
  vertical-align: text-top;
`
export const defaultSiteIds = [
  // Default Enertiv.
  '669e2c26-6afc-4323-a379-9877fdbd67e1', // Default Redaptive.
  'f0c4b7ff-3214-4323-992d-f73dacbd79d6', // Default Leviton.
  '15e7e4ce-44ec-486a-bb1f-cdc6ce533e25',
]
type FTState = {
  isCustomerStatusApiCalled: boolean
  isSiteStatusApiCalled: boolean
  isMeterDeleteApiCalled: boolean
  isFetchWaterMeterApiCalled: boolean
  meterStatusFetched: boolean
}

class SiteDetail extends PureComponent<FTProps, FTState> {
  constructor(props) {
    super(props)
    this.state = {
      isCustomerStatusApiCalled: false,
      isSiteStatusApiCalled: false,
      isMeterDeleteApiCalled: false,
      isFetchWaterMeterApiCalled: false,
      meterStatusFetched: false,
    }
  }

  componentDidMount() {
    const {
      actions,
      match: {
        params: { siteId },
      },
    } = this.props
    actions.clearUserSummaries()
    actions.fetchSite({
      siteId,
    })
    actions.fetchSiteExternalResources({
      siteId,
    })
    actions.fetchSiteHealth({
      siteId,
    })
    actions.fetchMeterList({
      siteId,
      orderBy: {
        field: 'name',
        sort: 'ASC',
      },
      filterBy: {
        field: 'resource',
        values: METER_RESOURCE_TYPES,
      },
    })
  }

  componentDidUpdate(prev) {
    const {
      siteEntity: { item: prevSite },
      customerEntity: { item: prevCustomer },
      history,
      location: { pathname },
      match: { url },
      energyStarEntity: {
        meta: { loading },
      },
    } = prev
    const {
      actions,
      siteEntity: { item: site },
      permissions,
      match: {
        params: { siteId },
      },
      energyStarEntity: { customerStatus },
      meterListEntity,
    } = this.props
    const { meterStatusFetched } = this.state
    const meterList = meterListEntity

    if (
      !this.state.isCustomerStatusApiCalled &&
      site &&
      features.energyStarIntegration.allMatchWithPermissions(permissions)
    ) {
      actions.getESCustomerIDStatus({
        customerId: site.customerId,
      })
      this.setState({
        isCustomerStatusApiCalled: true,
      })
    }

    if (
      customerStatus &&
      customerStatus.enabled &&
      !this.state.isSiteStatusApiCalled &&
      features.energyStarIntegration.allMatchWithPermissions(permissions)
    ) {
      actions.getESSiteIDStatus({
        redaptiveSiteId: siteId,
      })
      this.setState({
        isSiteStatusApiCalled: true,
      })
    }

    if (
      customerStatus &&
      customerStatus.enabled &&
      !this.state.isFetchWaterMeterApiCalled &&
      features.energyStarIntegration.allMatchWithPermissions(permissions)
    ) {
      actions.fetchWaterMeter({
        siteId,
      })
      this.setState({
        isFetchWaterMeterApiCalled: true,
      })
    }

    if (features.energyStarIntegration.allMatchWithPermissions(permissions)) {
      const isCurrEditPage = pathname.split('/').reverse()[0] === 'edit'

      if (
        isCurrEditPage &&
        loading &&
        !this.props.loading &&
        !this.state.isMeterDeleteApiCalled &&
        !this.props.energyStarEntity?.meta?.error
      ) {
        history.push(`${url}/energyStar`)
      }
    }

    if (meterList.items && !meterStatusFetched) {
      const meterIds = Array.from(
        new Set(meterList.items.map((meter) => meter.name)),
      )
      if (meterIds && meterIds.length) {
        actions.getRealTimeMeterStatus({ ids: meterIds })
        this.setState({ meterStatusFetched: true })
      }
    }

    if (!prevCustomer && !prevSite && site) {
      const { customerId } = site
      actions.fetchCustomer({
        customerId,
      })
    }
  }

  renderBreadcrumbs = () => {
    let items
    const {
      siteEntity: { item: site },
      customerEntity,
      match: {
        params: { customerId },
        url,
      },
    } = this.props

    if (customerId && customerEntity.item) {
      const { item: customer } = customerEntity
      items = [
        {
          href: '/account-management',
          text: 'Accounts',
        },
        {
          href: '/account-management/customers',
          text: 'Customers',
        },
        {
          href: `/account-management/customers/${customerId}`,
          text: customer.name,
        },
      ]
    } else {
      items = [
        {
          href: '/account-management',
          text: 'Accounts',
        },
        {
          href: '/account-management/sites',
          text: 'Sites',
        },
      ]
    }

    items = [
      ...items,
      {
        href: url,
        text: (site && site.validName) || '',
      },
    ]
    return <Breadcrumbs items={items} />
  }

  renderUsers = () => (
    <UsersTable
      listEntity={this.props.userSummaryListEntity}
      fetchListEntity={this.props.actions.fetchUserSummaries}
      siteId={this.props.match.params.siteId}
    />
  )

  handleAddPanelSuccess = (panelId: string) => {
    const {
      history,
      match: { url },
    } = this.props
    history.push(`${url}/panels/${panelId}/edit`)
  }

  renderPanels = () => {
    const { actions, panelListEntity } = this.props
    return (
      <AllPanelsTable
        showModalPanelForm3={() => {
          actions.showModalPanelForm3({
            siteId: this.props.match.params.siteId,
            handleSuccess: this.handleAddPanelSuccess,
            closeModal: actions.hideModal,
            editMode: false,
          })
        }}
        panelListEntity={panelListEntity}
        fetchPanels={actions.fetchPanelList}
        fetchAllPanels={actions.fetchAllPanels}
      />
    )
  }

  renderBilling = () => {
    const {
      siteEntity: { item: site },
    } = this.props
    let fields = []

    if (site) {
      const { contracts = [] } = site
      const contract = contracts[0] || {}
      fields = [
        {
          label: 'Energy Commitment',
          value:
            isValueSet(contract.energyCommitment) ?
              numeral(contract.energyCommitment).format('0,0')
            : '',
          description: 'The value of the contract commitment, in kWh',
          editable: false,
        },
        {
          label: 'Monthly Block',
          value:
            isValueSet(contract.monthlyBlock) ?
              numeral(contract.monthlyBlock).format('0,0')
            : '',
          description: 'Expected monthly savings, in kWh',
          editable: false,
        },
        {
          label: 'Energy Rate',
          value: isValueSet(contract.energyRate) ? contract.energyRate : '',
          description: 'Redaptive invoice rate, in $/kWh',
          editable: false,
        },
        {
          label: 'Utility Rate',
          value: isValueSet(contract.utilityRate) ? contract.utilityRate : '',
          description: 'Rate charged by the utility, in $/kWh',
          editable: false,
        },
        {
          label: 'Site Square Footage',
          description: 'Total area of site, in square feet',
          value:
            isValueSet(site.squareFootage) ?
              `${numeral(site.squareFootage).format('0,0')} SqFt`
            : '',
          editable: false,
        },
      ]
    }

    return <VerticalTable.Basic fields={fields} labelsInline={false} />
  }

  renderHealthStatusWithSpinner = (healthStatus) => {
    switch (this.props.siteHealthRequestStatus) {
      case requestStatusStates.ERROR:
        return 'Unavailable'

      case requestStatusStates.LOADED:
        return formatHealthStatus(healthStatus)

      default:
        return <LoadingSpinner />
    }
  }

  getMetersTableHeaders = () => [
    {
      fieldName: 'name',
      displayName: 'Meter Identifier',
    },
    {
      fieldName: 'panel',
      displayName: 'Panel',
      sortable: false,
    },
    {
      fieldName: 'meterOnline',
      displayName: 'Online Status',
      sortable: false,
    },
    {
      fieldName: 'fiedStatus',
      displayName: 'Field Status',
    },
    {
      fieldName: 'verified',
      displayName: 'Electron Verified',
    },
    {
      fieldName: 'lastReportDate',
      displayName: 'Last Reported',
      sortable: false,
    },
    {
      fieldName: 'labeledChannels',
      displayName: 'Labeled Channels',
    },
    {
      fieldName: 'source',
      displayName: 'Meter Type',
    },
    {
      fieldName: 'model',
      displayName: 'Meter Model',
    },
    {
      fieldName: 'resource',
      displayName: 'Resource Type',
      sortable: false,
    },
    {
      fieldName: 'healthStatusLastMonth',
      displayName: (
        <RequestStateFieldLabel
          fieldLabel={
            <>
              Data Availability:
              <br />
              Last Month
            </>
          }
          requestStatus={this.props.siteHealthRequestStatus}
        />
      ),
      sortable: false,
    },
    {
      fieldName: 'healthStatusCurrentMonth',
      displayName: (
        <RequestStateFieldLabel
          fieldLabel={
            <>
              Data Availability:
              <br />
              Month to Date
            </>
          }
          requestStatus={this.props.siteHealthRequestStatus}
        />
      ),
      sortable: false,
    },
  ]

  lastMesurementDateCell = (value: string, tz: string) => {
    if (value === 'loading') return <div>Loading...</div>
    if (value.toLowerCase() === 'unavailable') return <div>Unavailable</div>
    const format = 'MMMM DD, YYYY hh:mm:ss A z'
    const date = renderTimestamp(value, tz, format)
    return date
  }

  onlineStatusCell = (value: boolean | 'loading') => {
    if (value === 'loading') return <div>Loading...</div>
    return <StatusIconItem status={value} />
  }

  renderTableRow = ({
    id,
    name,
    panelNames,
    labeledTotal,
    meterType,
    model,
    healthStatusLastMonth,
    healthStatusCurrentMonth,
    resource,
    verified,
    active,
    deactivationReason,
    siteTimezone,
  }) => {
    const getVerified = () => {
      if (isRedaptiveMeter(meterType) || meterType === 'Redaptive Gas') {
        return verified ?
            <>
              <InlineVerifiedIcon />
              {' Yes'}
            </>
          : <>
              <InlineNotVerifiedIcon />
              {' No'}
            </>
      }
      return 'N/A'
    }

    return (
      <tr key={id}>
        <td className='name'>
          <StyledLink href={`${this.props.match.url}/meters/${id}`}>
            {name}
          </StyledLink>
        </td>
        <td>{panelNames && panelNames.join(', ')}</td>
        <td>
          {this.onlineStatusCell(
            this.props.meterStatusById[id] ?
              this.props.meterStatusById[id].onlineStatus
            : 'loading',
          )}
        </td>
        <td>
          <StatusIconItem
            status={active}
            labelText={active ? 'Active' : deactivationReason || 'Other'}
          />
        </td>
        <td>{getVerified()}</td>
        <td>
          {this.lastMesurementDateCell(
            this.props.meterStatusById[id] ?
              this.props.meterStatusById[id].originalLastMeasurementDate
            : 'loading',
            siteTimezone,
          )}
        </td>
        <td>{labeledTotal}</td>
        <td>{meterType}</td>
        <td>{model}</td>
        <td>{capitalCase(resource) || '-'}</td>
        <td>
          {renderHealthStatus(
            this.props.siteHealthRequestStatus,
            healthStatusLastMonth,
          )}
        </td>
        <td>
          {renderHealthStatus(
            this.props.siteHealthRequestStatus,
            healthStatusCurrentMonth,
          )}
        </td>
      </tr>
    )
  }

  resetMeterStatusAPIFlag = () => {
    this.setState({ meterStatusFetched: false })
  }

  renderMeters = () => (
    <DeprecatedEntityListPane
      striped
      entity={this.props.meterListEntity}
      flagResetters={[this.resetMeterStatusAPIFlag]}
      loadEntity={this.props.actions.fetchMeterList}
      loadEntityProps={{
        orderBy: {
          field: 'name',
          sort: 'ASC',
        },
        siteId: this.props.match.params.siteId,
      }}
      tableHeaders={this.getMetersTableHeaders()}
      renderTableRow={this.renderTableRow}
      showMetersFilter
      filterParam='resource'
      hideAllPagination
      paginationSize={[50, 20]}
    />
  )

  renderESSiteDetails = () => {
    const {
      energyStarEntity,
      meterListEntity: { items: meters },
      actions,
      match: {
        params: { siteId },
      },
    } = this.props

    const onSubmit = (values) => {
      this.setState({
        isMeterDeleteApiCalled: false,
      })
      actions.updateESSiteIDStatus({
        redaptiveSiteId: siteId,
        reqData: values,
      })
    }

    const onMeterDelete = (meterId) => {
      this.setState({
        isMeterDeleteApiCalled: true,
      })
      actions.deleteESMeter({
        meterId,
      })
    }

    const refreshSiteData = () => {
      actions.getESSiteIDStatus({
        redaptiveSiteId: siteId,
      })
    }

    const clearError = () => {
      actions.clearError()
    }

    return (
      <ESSiteStatus
        onSubmit={onSubmit}
        onMeterDelete={onMeterDelete}
        energyStarEntity={energyStarEntity}
        meters={meters}
        clearError={clearError}
        refreshSiteData={refreshSiteData}
      />
    )
  }

  renderMain = () => {
    const {
      siteEntity: { item: site },
      siteExternalResources,
      siteHealth,
      customerEntity: {
        item: customer,
        meta: { loading },
      },
      meterListEntity: { items: meters },
      match: { url },
      permissions,
      energyStarEntity: {
        meta: { loading: ESloading },
        customerStatus,
      },
    } = this.props
    const hydropointSiteId =
      siteExternalResources &&
      siteExternalResources.find((obj) => obj.resourceProvider === 'HYDROPOINT')
        ?.externalId
    const contractTypeNames = {}
    CONTRACT_TYPES.forEach((contractType) => {
      contractTypeNames[contractType.id] = contractType.name
    })
    let fields = []

    if (site) {
      const { contracts = [] } = site
      fields = [
        {
          label: 'Name',
          value: site.validName,
          editable: false,
        },
        {
          label: 'Address',
          // eslint-disable-next-line react/no-array-index-key
          renderField: () =>
            site.address.map(
              (l, i) => l && <div key={`address-line-${i}`}>{l}</div>,
            ),
          editable: false,
        },
        {
          label: 'Customer',
          renderField: () =>
            customer && (
              <StyledLink href={`/account-management/customers/${customer.id}`}>
                {customer.name}
              </StyledLink>
            ),
          editable: false,
        },
        {
          label: 'Opportunity IDs',
          renderField: () => {
            if (!contracts.length) {
              return <div>-</div>
            }

            return [...contracts]
              .sort(naturallySortContracts)
              .map((contract) => (
                <div>
                  {`${contract.opportunityId} (
                  ${contract.opportunityType}${
                    contract.opportunityTypeLevel2 ?
                      ` - ${contract.opportunityTypeLevel2}`
                    : ''
                  })`}
                </div>
              ))
          },
          editable: false,
        },
        {
          label: 'Electric Data Start Date',
          value:
            site.ingestionDataStartElectricity &&
            moment(site.ingestionDataStartElectricity)
              .utc()
              .format('MM/DD/YYYY'),
          editable: false,
        },
        {
          label: 'Natural Gas Data Start Date',
          value:
            site.ingestionDataStartNaturalGas &&
            moment(site.ingestionDataStartNaturalGas)
              .utc()
              .format('MM/DD/YYYY'),
          editable: false,
        },
        {
          label: 'Water Data Start Date',
          value:
            site.ingestionDataStartWater &&
            moment(site.ingestionDataStartWater).utc().format('MM/DD/YYYY'),
          editable: false,
        },
        {
          label: 'Time Zone',
          value: site.prettyTimezone,
          editable: false,
        },
        {
          label: 'Hydropoint Site ID',
          value: hydropointSiteId,
          editable: false,
        },
        {
          label: 'Display in Energy Dashboard',
          value: site.active ? 'Yes' : 'No',
          editable: false,
        },
        {
          label: 'Enable Equipment View',
          value: site.enableEquipmentView ? 'Yes' : 'No',
          editable: false,
        },
        {
          label: 'Date Created',
          value: site.dateCreated,
          editable: false,
        },
        {
          label: 'Last Updated',
          value: site.lastUpdated,
          editable: false,
        },
        {
          label: 'Branding',
          value: themes.getThemeNameById(site.theme),
          editable: false,
        },
        {
          label: 'Electric Site Data Availability: Last Month',
          value: this.renderHealthStatusWithSpinner(
            siteHealth.electricSiteHealthStatusLastMonth,
          ),
          editable: false,
        },
        {
          label: 'Electric Site Data Availability: Month to Date',
          value: this.renderHealthStatusWithSpinner(
            siteHealth.electricSiteHealthStatusCurrentMonth,
          ),
          editable: false,
        },
        {
          label: 'Natural Gas Site Data Availability: Last Month',
          value: this.renderHealthStatusWithSpinner(
            siteHealth.gasSiteHealthStatusLastMonth,
          ),
          editable: false,
        },
        {
          label: 'Natural Gas Site Data Availability: Month to Date',
          value: this.renderHealthStatusWithSpinner(
            siteHealth.gasSiteHealthStatusCurrentMonth,
          ),
          editable: false,
        },
      ]
    }

    const editESSiteStatusLink = {
      href: `${url}/energyStar/edit`,
      text: 'Edit',
      withDefaultTab: true,
    }
    const tabs = [
      {
        tab: 'meters',
        text: 'Meters',
        render: this.renderMeters,
      },
      {
        tab: 'billing',
        text: 'Billing Inputs',
        render: this.renderBilling,
      },
      {
        tab: 'users',
        text: 'Users',
        render: this.renderUsers,
      },
      {
        tab: 'panels',
        text: 'Panels',
        render: this.renderPanels,
      },
    ]

    if (
      features.energyStarIntegration.allMatchWithPermissions(permissions) &&
      customerStatus &&
      customerStatus.enabled
    ) {
      tabs.push({
        tab: 'energyStar',
        text: 'Energy Star',
        render: this.renderESSiteDetails,
        rightHandLink: !ESloading && editESSiteStatusLink,
      })
    }

    return (
      <Styles>
        {this.renderBreadcrumbs()}
        <Title>Site Detail</Title>

        {site && !loading && <VerticalTable.Basic fields={fields} />}
        {meters && <TabPane tabs={tabs} />}
      </Styles>
    )
  }

  render() {
    const {
      meterListEntity: { items: meters },
      match: {
        url,
        params: { siteId },
      },
      siteEntity: { item: site },
      permissions,
    } = this.props
    const actions = []

    // If this site is not one of the default sites...
    if (!defaultSiteIds.includes(siteId)) {
      actions.push({
        href: `${url}/edit`,
        label: 'Edit',
      })
    }

    if (
      meters.length > 0 &&
      features.meterExport.allMatchWithPermissions(permissions)
    ) {
      actions.push({
        href: `${url}/meter-export`,
        label: 'Export Meter Data',
      })
    }

    if (site && site.active) {
      actions.push({
        href: `${getDashboardUrl()}/billing/${siteId}`,
        external: true,
        label: 'View in Dashboard',
      })
    }

    return (
      <div className='SiteDetailPage'>
        <ActionPaneView actions={actions} renderMain={this.renderMain} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(siteActions, dispatch),
    ...bindActionCreators(customerActions, dispatch),
    ...bindActionCreators(userSummaryActions, dispatch),
    ...bindActionCreators(meterActions, dispatch),
    ...bindActionCreators(panelActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(energyStarIntegrationActions, dispatch),
    ...bindActionCreators(meterStatusActions, dispatch),
  },
})

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { siteId, customerId: customerIdUrl },
    },
  } = props
  const siteEntity = selectSiteEntity(state, siteId)
  let customerId = customerIdUrl

  if (!customerId && siteEntity.item) {
    ;({ customerId } = siteEntity.item)
  }

  const customerEntity = selectCustomerEntity(state, customerId)
  return {
    customerEntity,
    meterListEntity: selectMeterListWithHealthEntity(state),
    permissions: authSelectors.selectPermissions(state),
    siteEntity: {
      ...siteEntity,
      meta: { ...siteEntity.meta, newOpportunity: null },
    },
    panelListEntity: selectPanelListEntity(state),
    energyStarEntity: energyStarSubmissionsEntity(state),
    siteHealth: selectSiteHealth(state),
    siteHealthRequestStatus: selectSiteHealthRequestStatus(state),
    siteExternalResources: selectSiteExternalResources(state),
    userSummaryListEntity: getUserSummaryListEntity(state),
    meterStatusById: selectRTMeterById(state),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiteDetail),
)
