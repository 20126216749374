import axios from 'axios'
import { push } from 'connected-react-router'
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'

import { makeActionTypes } from './utils'
import { consoleApiUrl, defaultHeaders } from '../api'
import { handleAxiosError } from '../api/utils'
import { handleSagaError } from '../sagas/utils'
import type { FTUserPermissions } from '../authorization/models/userPermissions'
import '../authorization/models/userPermissions'
import { ERR_SERVER_ERROR } from '../constants/error'
import { isVariantActive } from '../utils'

type FTGroups = Array<string>
type FTPermissionsPayload = {
  groups: FTGroups
  permissions: FTUserPermissions
}
type FTEntityState = {
  groups: FTGroups | null | undefined
  permissions: FTUserPermissions | null | undefined
  loading: boolean
  error: string
}
type FTState = {
  auth: FTEntityState
}
export const types = {
  ...makeActionTypes('FETCH_USER_PERMISSIONS'),
  ...makeActionTypes('FORGOT_PASSWORD_REQUEST'),
}
export const actions = {
  fetchUserPermissions: () => ({
    type: types.FETCH_USER_PERMISSIONS,
  }),
  fetchUserPermissionsSuccess: (payload: FTPermissionsPayload) => ({
    type: types.FETCH_USER_PERMISSIONS_SUCCESS,
    payload,
  }),
}
export const selectors = {
  isLoadingPermissions: (state: FTState) => state.auth.loading !== false,
  selectPermissions: (state: FTState) => state.auth.permissions,
  selectGroups: (state: FTState) => state.auth.groups,
}
const initialState = {
  groups: undefined,
  permissions: undefined,
  loading: true,
  error: '',
}

function reducer(
  state: FTEntityState = initialState,
  action: Record<string, any>,
) {
  switch (action.type) {
    case types.FETCH_USER_PERMISSIONS:
      return { ...state, loading: true, error: '' }

    case types.FETCH_USER_PERMISSIONS_ERROR:
      return { ...state, loading: false, error: action.error }

    case types.FETCH_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        groups: action.payload.groups,
        permissions: action.payload.permissions,
      }

    default:
      return state
  }
}

export default reducer

class API {
  static forgotPasswordRequest(email: string): any {
    const url = `${consoleApiUrl()}/passwordreset`
    const postData = {
      username: email,
      theme: 'REDAPTIVE',
    }
    return axios
      .post(url, postData)
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }

  static fetchUserPermissions() {
    const url = `${consoleApiUrl()}/permissions`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }) => data)
      .catch(handleAxiosError)
  }
}

function* forgotPasswordRequest({
  email,
}: {
  email: string
}): Generator<any, void, any> {
  try {
    yield call(API.forgotPasswordRequest, email)
    yield put({
      type: types.FORGOT_PASSWORD_REQUEST_SUCCESS,
    })
  } catch (error) {
    yield put({
      type: types.FORGOT_PASSWORD_REQUEST_ERROR,
    })
    yield handleSagaError(ERR_SERVER_ERROR, error)
  }
}

function* fetchUserPermissionsSaga(): Generator<any, void, any> {
  try {
    const { groups, permissions } = yield call(API.fetchUserPermissions)

    if (isVariantActive('2990mock')) {
      groups.push('proposal-operations')
      permissions['/spec-sheets/list'] = 'admin'
      permissions['/spec-sheets/upload-request'] = 'admin'
    }

    yield put(
      actions.fetchUserPermissionsSuccess({
        groups,
        permissions,
      }),
    )
  } catch (e) {
    yield handleSagaError(types.FETCH_USER_PERMISSIONS_ERROR, e)
    yield put(push('/not-authorized'))
  }
}

export const sagas = [
  takeLeading(types.FORGOT_PASSWORD_REQUEST, forgotPasswordRequest),
  takeEvery(types.FETCH_USER_PERMISSIONS, fetchUserPermissionsSaga),
]
