import axios from 'axios'
import { schema } from 'normalizr'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { actions as messageActions } from './messages'
import type { FTPhaseGroupEntity } from './phaseGroups'
import { makeActionTypes } from './utils'
import { consoleApiUrl, defaultHeaders } from '../api'
import { handleAxiosError } from '../api/utils'
import mockCircuitsData from '../mockData/panelCircuitsMockdata'
import { handleSagaError } from '../sagas/utils'
import type { FTSortable } from '../types'
import { isVariantActive } from '../utils'
import './phaseGroups'

// Action Types
export const types = {
  ...makeActionTypes('FETCH_PANEL_CIRCUITS'),
  ...makeActionTypes('UPDATE_PANEL_CIRCUITS'),
  ...makeActionTypes('DELETE_PANEL_CIRCUITS'),
}

export type FTPanelCircuit = {
  id?: string
  isMetered?: boolean
  isCreated?: boolean
  breakerNumber: number
  sideBreakerOrder?: string
  phasingPattern?: string
  breakerType?: string
  panelFeedId?: string
  panelFeedName?: string
  phase?: string
  phaseGroupSummary?: FTPhaseGroupEntity
  buildingSystemId?: string
  equipmentName?: string
  buildingArea?: string
  CTSizeExpected?: string
  description?: string
  amperage?: string
  siteId?: string
  panelId?: string
  macAddress?: string
  meterId?: string
}
type FTPanelCircuitResponse = {
  circuits: Array<FTPanelCircuit>
}
export type FTUpdatePanelCircuitsActionPayload = {
  panelId: string
  circuits: Array<FTPanelCircuit>
  fromPanelModal?: boolean
  panelName?: string
  isNumbered?: boolean
}

export interface FTUpdatePanelCircuitsAction {
  type: typeof types.UPDATE_PANEL_CIRCUITS
  params: FTUpdatePanelCircuitsActionPayload
}
export type FTDeletePanelCircuitsAction = {
  circuitIds: Array<FTPanelCircuit>
}
export type FTFetchPanelCircuitsAction = {
  panelId: string
  orderBy?: FTSortable
}

export type FTPanelCircuitMetaState = {
  error: string
  deleteLoading: boolean
  deleteComplete: boolean
}
export type FTPanelCircuitEntityState = {
  byId: Record<string, FTPanelCircuit>
  items: Array<FTPanelCircuit>
  meta: FTPanelCircuitMetaState
}
type FTState = {
  entities: {
    panelCircuits: FTPanelCircuitEntityState
  }
}

export const actions = {
  fetchPanelCircuits: (props: FTFetchPanelCircuitsAction) => ({
    type: types.FETCH_PANEL_CIRCUITS,
    ...props,
  }),
  updatePanelCircuits: (props: FTUpdatePanelCircuitsAction) => ({
    type: types.UPDATE_PANEL_CIRCUITS,
    params: { ...props },
  }),
  deletePanelCircuits: (props: FTDeletePanelCircuitsAction) => ({
    type: types.DELETE_PANEL_CIRCUITS,
    ...props,
  }),
}
const initialState = {
  byId: {},
  items: [],
  meta: {
    error: '',
    loading: false,
    updateLoading: false,
    updateError: '',
    deleteLoading: false,
    deleteComplete: true,
    deleteError: '',
  },
}
export const entitySchema = new schema.Entity('panelCircuits')

const entityById = (action, state) => {
  const temp = {}
  action.payload.forEach((item) => {
    temp[item.id] = { ...item }
  })
  return { ...state, ...temp }
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_PANEL_CIRCUITS:
      return initialState.byId

    case types.FETCH_PANEL_CIRCUITS_SUCCESS:
      return entityById(action, state)

    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_PANEL_CIRCUITS:
      return initialState.items

    case types.FETCH_PANEL_CIRCUITS_SUCCESS:
      return [...action.payload]

    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_PANEL_CIRCUITS:
      return {
        ...state,
        error: '',
        updateError: '',
        deleteError: '',
        loading: true,
      }

    case types.FETCH_PANEL_CIRCUITS_ERROR:
      return { ...state, error: action.error, loading: false }

    case types.FETCH_PANEL_CIRCUITS_SUCCESS:
      return { ...state, error: '', loading: false }

    case types.UPDATE_PANEL_CIRCUITS:
      return { ...state, updateError: '', updateLoading: true }

    case types.UPDATE_PANEL_CIRCUITS_ERROR:
      return { ...state, updateError: action.error, updateLoading: false }

    case types.UPDATE_PANEL_CIRCUITS_SUCCESS:
      return { ...state, updateError: '', updateLoading: false }

    case types.DELETE_PANEL_CIRCUITS:
      return {
        ...state,
        deleteError: '',
        deleteLoading: true,
        deleteComplete: false,
      }

    case types.DELETE_PANEL_CIRCUITS_ERROR:
      return {
        ...state,
        deleteError: action.error,
        deleteLoading: false,
        deleteComplete: false,
      }

    case types.DELETE_PANEL_CIRCUITS_SUCCESS:
      return {
        ...state,
        deleteError: '',
        deleteLoading: false,
        deleteComplete: true,
      }

    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
})
export const selectPanelCircuitsEntity = (
  state: FTState,
): FTPanelCircuitEntityState => state.entities.panelCircuits

export const selectPanelCircuitsMeta = (
  state: FTState,
): FTPanelCircuitMetaState => state.entities.panelCircuits.meta

const API = {
  fetchPanelCircuits: ({ panelId }: FTFetchPanelCircuitsAction) => {
    if (isVariantActive('797mock')) {
      return Promise.resolve(mockCircuitsData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 2000)),
      )
    }

    const url = `${consoleApiUrl()}/panels/${panelId}/circuits?order=breakerNumber`
    return axios
      .get(url, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTPanelCircuitResponse }) => data)
      .catch(handleAxiosError)
  },
  updatePanelCircuits: ({
    params,
  }: {
    params: FTUpdatePanelCircuitsAction
  }) => {
    const paramsData = structuredClone(params)
    delete paramsData.fromPanelModal
    delete paramsData.panelName
    delete paramsData.isNumbered

    if (isVariantActive('797mock')) {
      return Promise.resolve(mockCircuitsData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 2000)),
      )
    }

    const url = `${consoleApiUrl()}/circuits`
    return axios
      .post(url, paramsData, {
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTPanelCircuitResponse }) => data)
      .catch(handleAxiosError)
  },
  deletePanelCircuits: ({ circuitIds }: FTDeletePanelCircuitsAction) => {
    if (isVariantActive('797mock')) {
      return Promise.resolve(mockCircuitsData).then(
        (data) =>
          new Promise((resolve) => setTimeout(() => resolve(data), 2000)),
      )
    }

    const url = `${consoleApiUrl()}/circuits/bulk`
    return axios
      .delete(url, {
        data: {
          circuitIds,
        },
        headers: defaultHeaders(),
      })
      .then(({ data }: { data: FTPanelCircuitResponse }) => data)
      .catch(handleAxiosError)
  },
}

function* fetchPanelCircuitsSaga(
  params: FTFetchPanelCircuitsAction,
): Generator<any, void, any> {
  try {
    const response: FTPanelCircuitResponse = yield call(
      API.fetchPanelCircuits,
      params,
    )
    yield put({
      type: types.FETCH_PANEL_CIRCUITS_SUCCESS,
      payload: response.circuits,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_PANEL_CIRCUITS_ERROR, e)
  }
}

function* updatePanelCircuitsSaga(
  params: FTUpdatePanelCircuitsAction,
): Generator<any, void, any> {
  try {
    const { panelName, isNumbered, fromPanelModal } = params.params
    const response: FTPanelCircuitResponse = yield call(
      API.updatePanelCircuits,
      params,
    )
    if (fromPanelModal) {
      yield put(
        messageActions.showMessage({
          title: `The ${
            isNumbered ? 'circuits' : 'switches'
          } are associated to the panel ${panelName}`,
          messageId: 'updatePanelCircuitsSuccessFromModal',
          position: 'fixed',
          type: 'success',
        }),
      )
    }
    yield put({
      type: types.UPDATE_PANEL_CIRCUITS_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.UPDATE_PANEL_CIRCUITS_ERROR, e)
  }
}

function* deletePanelCircuitsSaga(
  params: FTDeletePanelCircuitsAction,
): Generator<any, void, any> {
  try {
    const response: FTPanelCircuitResponse = yield call(
      API.deletePanelCircuits,
      params,
    )
    yield put({
      type: types.DELETE_PANEL_CIRCUITS_SUCCESS,
      payload: response,
    })
  } catch (e) {
    yield handleSagaError(types.DELETE_PANEL_CIRCUITS_ERROR, e)
  }
}

export const sagas = [
  takeLatest(types.FETCH_PANEL_CIRCUITS, fetchPanelCircuitsSaga),
  takeLatest(types.UPDATE_PANEL_CIRCUITS, updatePanelCircuitsSaga),
  takeLatest(types.DELETE_PANEL_CIRCUITS, deletePanelCircuitsSaga),
]
