/* eslint-disable no-unused-vars */
import { Parser } from '@json2csv/plainjs'
import Tippy from '@tippyjs/react'
import FileSaver from 'file-saver'
import moment from 'moment'
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { CellProps, FilterValue, IdType, Row } from 'react-table'
import { bindActionCreators, Dispatch } from 'redux'

import {
  alwaysLockedColumns,
  columnIds,
  DataAvailabiltyFilterValueEnum,
  DataSourceValueMap,
  defaultSort,
  DeltaFilterValueEnum,
  errorMap,
  ResourceValueMap,
  statusEnum,
  tabColumns,
  tabs,
  tabsEnum,
} from './helpers/utils'
import {
  ConfirmationScreen,
  ConfirmationTitleStyled,
  DataCaptureStatusStatusIndicatorStyled,
  HeaderButton,
  InfoCircleOutlineIconStyled,
  LoadingSpinnerWrapperStyled,
  MonthPickerWrapperStyles,
  StatusColor,
  SubmissionPageOuter,
  SubmitButton,
  TabCheckedStyled,
  TabCountStyled,
  TableWrapperStyled,
} from './styles'
import ActionPaneView from '../../components/ActionPaneView'
import CheckIcon from '../../components/Icons/CheckIcon'
import FileDownloadIcon from '../../components/Icons/FileDownloadIcon'
import RefreshIcon from '../../components/Icons/RefreshIcon'
import MonthPicker from '../../components/MonthPicker'
import RedaptiveReactTable7, {
  SelectColumnMultiFilterListSelectorStyled,
} from '../../components/RedaptiveReactTable7'
import Spinner from '../../components/Spinner'
import TabPane2 from '../../components/Tabs/TabPane2'
import { selectors as authSelectors } from '../../ducks/auth'
import {
  actions as energyStarIntegrationActions,
  energyStarSubmissionsEntity,
} from '../../ducks/energyStarIntegration'
import { TSEnergyStarSubmissions } from '../../ducks/energyStarIntegration/types'
import { actions as messagesActions } from '../../ducks/messages'
import { actions as modalActions } from '../../ducks/modal'
import usePrevious from '../../hooks/usePrevious'
import { formatHealthStatus, isValueSet } from '../../utils'
import { colors } from '../../utils/themes'

const TAB_ENUM_VALUES = Object.values(tabsEnum)

const EnergyStarSubmissionPage: FC<PropsFromRedux & RouteComponentProps> = (
  props: PropsFromRedux & RouteComponentProps,
) => {
  const {
    actions,
    history,
    submissionsData: { items: submissionsDataItems, exportData },
    energyStarSubmissionsLoading,
    suppressLoading,
    location: { pathname },
  } = props
  const tabNameFromPath = pathname.split('/')[4]
  const activeTab =
    TAB_ENUM_VALUES.includes(tabNameFromPath) ? tabNameFromPath : (
      tabsEnum.NOT_SUBMITTED
    )
  const summarytableInstanceRef = useRef()
  const prevValue = usePrevious({
    suppressLoading,
  })
  const [filterValues, setFilterValues] = useState<
    Partial<Record<keyof TSEnergyStarSubmissions, string[]>>
  >({})
  const [rowData, setRowData] = useState(submissionsDataItems)
  const [clearFilter, setClearFilter] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedYearMonth, setSelectedYearMonth] = useState(
    moment().subtract(1, 'months').format('YYYY-MM'),
  )
  const [showMonthPickerDropDown, setShowMonthPickerDropDown] = useState(false)
  useEffect(() => {
    actions.fetchSubmissionsData({
      yearMonth: selectedYearMonth,
    })
  }, [actions, selectedYearMonth])

  useEffect(() => {
    if (Object.keys(filterValues).length) {
      setClearFilter(true)
    }
  }, [activeTab, filterValues])

  useEffect(() => {
    setRowData(submissionsDataItems)
  }, [submissionsDataItems])
  useEffect(() => {
    if (prevValue && prevValue.suppressLoading && !suppressLoading) {
      actions.showMessage({
        messageId: 'resyncSuccessful',
        title: 'Values have been successfully resynched.',
        type: 'success',
        position: 'fixed',
      })
      setTimeout(() => actions.hideMessage('resyncSuccessful'), 10000)
    }
  }, [actions, prevValue, suppressLoading])

  const filteredByTab = useMemo(() => {
    const tabsResults = Object.values(tabsEnum).reduce<{
      [key: string]: { total: number; items: TSEnergyStarSubmissions[] }
    }>((acc, tab) => {
      acc[tab] = { total: 0, items: [] }
      return acc
    }, {})
    rowData.forEach((item) => {
      const tempItem = { ...item }
      let tabName

      if (
        tempItem.status === statusEnum.NOT_SUBMITTED ||
        tempItem.status === statusEnum.FAILED
      ) {
        tabName = tabsEnum.NOT_SUBMITTED
      } else {
        tabName = tabsEnum.SUBMITTED
      }

      // tempItem.status = statusViewEnum[tempItem.status];
      tabsResults[tabName].items.push(tempItem)
      tabsResults[tabName].total += 1
    })
    return tabsResults
  }, [rowData])

  const getColorForDataAvailability = (value: number) => {
    if (!value) return ''
    if (value < 95) return '#C25353'
    if (value >= 95 && value < 99) return '#E7AC4C'
    if (value >= 99) return '#A9B988'
    return ''
  }

  const getColorForDelta = (value: number) => {
    if (!value) return ''
    if (Math.abs(value) > 10) return '#C25353'
    if (Math.abs(value) > 5 && Math.abs(value) <= 10) return '#E7AC4C'
    if (Math.abs(value) <= 5) return '#A9B988'
    return ''
  }

  const renderToolTip = (error: undefined | keyof typeof errorMap) => {
    if (!error) return null
    return (
      <Tippy content={errorMap[error] || errorMap[400]} delay={500}>
        <InfoCircleOutlineIconStyled />
      </Tippy>
    )
  }

  const RenderCustomCell = useCallback(
    ({
      value,
      row: { original },
      column: { id },
    }: CellProps<TSEnergyStarSubmissions>) => {
      if (id === 'status') {
        let statusViewValue: string | ReactElement = 'Not Submitted'
        let color = colors.blue5

        if (value === statusEnum.FAILED) {
          color = colors.red2
          statusViewValue = (
            <>
              Failed
              {renderToolTip(original.errorStatusCode)}
            </>
          )
        }

        if (value === statusEnum.IN_PROGRESS) {
          color = colors.orange2
          statusViewValue = 'In Progress'
        }

        if (value === statusEnum.SUBMITTED) {
          color = colors.green
          statusViewValue = 'Successfully Submitted'
        }

        return <StatusColor color={color}>{statusViewValue}</StatusColor>
      }

      if (id === columnIds.ResourceType) {
        return ResourceValueMap[value] || value
      }

      if (id === columnIds.DataSource) {
        return DataSourceValueMap[value] || value
      }

      if (id === columnIds.DeltaEnergyConsumption) {
        return (
          <>
            <DataCaptureStatusStatusIndicatorStyled
              color={getColorForDelta(value)}
              type='line'
            />
            {typeof value !== 'undefined' && value != null ?
              `${Math.round(value)}%`
            : '-'}
          </>
        )
      }

      if (id === columnIds.DataAvailability) {
        return (
          <>
            <DataCaptureStatusStatusIndicatorStyled
              color={getColorForDataAvailability(
                isValueSet(value) ? Math.floor(value * 100) : 0,
              )}
              type='line'
            />
            {formatHealthStatus(value)}
          </>
        )
      }

      if (id === columnIds.PreviousMonthEnergyConsumption && !value) {
        return <>-</>
      }

      return value
    },
    [],
  )

  const MutipleSelectFilter = useCallback(
    ({
      column: { setFilter, preFilteredRows, id },
    }: {
      column: {
        preFilteredRows: Array<Row<TSEnergyStarSubmissions>>
        setFilter: (
          updater: ((filterValue: FilterValue) => FilterValue) | FilterValue,
        ) => void
        id: keyof TSEnergyStarSubmissions
      }
    }) => {
      let items

      if (id === columnIds.DeltaEnergyConsumption) {
        items = useMemo(
          () =>
            Object.keys(DeltaFilterValueEnum).map((value) => ({
              id: DeltaFilterValueEnum[value],
              name: value,
            })),
          [],
        )
      } else if (id === columnIds.DataAvailability) {
        items = useMemo(
          () =>
            Object.keys(DataAvailabiltyFilterValueEnum).map((value) => ({
              id: DataAvailabiltyFilterValueEnum[value],
              name: value,
            })),
          [],
        )
      } else {
        items = useMemo(
          () =>
            [...new Set(preFilteredRows.map((row) => row.values[id]))].map(
              (value) => {
                if (id === 'status') {
                  let statusViewValue = 'Not Submitted'

                  if (value === statusEnum.FAILED) {
                    statusViewValue = 'Failed'
                  }

                  if (value === statusEnum.IN_PROGRESS) {
                    statusViewValue = 'In Progress'
                  }

                  if (value === statusEnum.SUBMITTED) {
                    statusViewValue = 'Successfully Submitted'
                  }

                  return {
                    id: value,
                    name: statusViewValue,
                  }
                }

                if (id === columnIds.ResourceType) {
                  return {
                    id: value,
                    name: ResourceValueMap[value] || value,
                  }
                }

                if (id === columnIds.DataSource) {
                  return {
                    id: value,
                    name: DataSourceValueMap[value] || value,
                  }
                }

                return {
                  id: value,
                  name: value,
                }
              },
            ),
          [
            id,
            activeTab,
            filteredByTab,
            rowData,
            selectedYearMonth,
            preFilteredRows,
          ],
        )
      }

      if (clearFilter && filterValues[id]) {
        delete filterValues[id]
        setFilter([])
        setFilterValues(filterValues)
      }

      if (!Object.keys(filterValues).length) {
        setClearFilter(false)
      }

      return (
        <SelectColumnMultiFilterListSelectorStyled
          isMulti={
            id !== columnIds.DeltaEnergyConsumption &&
            id !== columnIds.DataAvailability
          }
          items={items}
          unsettable={false}
          selectedItems={items.filter((item) =>
            item && filterValues[id] ?
              filterValues[id].includes(item.id)
            : false,
          )}
          selectedItem={
            filterValues[id] ?
              items.find((item) => filterValues?.[id]?.[0] === item.id)
            : null
          }
          updateValueMulti={(values) => {
            filterValues[id] = values.map(({ value }) => value)
            setFilterValues(filterValues)
            setFilter(filterValues[id])
          }}
          updateValue={({ value }) => {
            filterValues[id] = [value]
            setFilterValues(filterValues)
            setFilter(filterValues[id])
          }}
          menuShouldBlockScroll
        />
      )
    },
    [
      activeTab,
      clearFilter,
      filterValues,
      filteredByTab,
      rowData,
      selectedYearMonth,
    ],
  )

  const MultipleSelectFilterFunction = useCallback(
    (
      rows: Array<Row<TSEnergyStarSubmissions>>,
      [id]: Array<IdType<TSEnergyStarSubmissions>>,
      filterValue: FilterValue,
    ) => {
      if (!filterValue || !filterValue.length) return rows

      if (id === columnIds.DeltaEnergyConsumption) {
        if (filterValue[0] === DeltaFilterValueEnum['N/A']) {
          return rows.filter(
            ({ original: { deltaEnergyConsumption } }) =>
              deltaEnergyConsumption === null,
          )
        }

        const maxValue = Math.max(...filterValue)
        return rows.filter(({ original: { deltaEnergyConsumption } }) => {
          if (deltaEnergyConsumption === null) return false
          return Math.abs(deltaEnergyConsumption) <= maxValue
        })
      }

      if (id === columnIds.DataAvailability) {
        const minValue = Math.min(...filterValue)

        if (minValue < 90) {
          return rows.filter(
            ({ original: { dataAvailability } }) =>
              Math.abs(
                isValueSet(dataAvailability) ?
                  Math.floor((dataAvailability ?? 0) * 100)
                : 0,
              ) < minValue,
          )
        }

        return rows.filter(
          ({ original: { dataAvailability } }) =>
            Math.abs(
              isValueSet(dataAvailability) ?
                Math.floor((dataAvailability ?? 0) * 100)
              : 0,
            ) >= minValue,
        )
      }

      return rows.filter(({ original }) =>
        filterValue.includes(original[id as keyof TSEnergyStarSubmissions]),
      )
    },
    [],
  )

  useEffect(() => {
    if (
      !tabNameFromPath ||
      !Object.values(tabsEnum).some((value) => value === tabNameFromPath)
    ) {
      const pathArr = pathname.split('/')
      pathArr[4] = tabsEnum.NOT_SUBMITTED
      history.push(pathArr.slice(0, 5).join('/'))
    }
  }, [history, pathname, tabNameFromPath])

  const tableData = useMemo(
    () => filteredByTab[activeTab].items || [],
    [activeTab, filteredByTab, rowData],
  )
  const TableColumns = useMemo(
    () =>
      tabColumns({
        MutipleSelectFilter,
        MultipleSelectFilterFunction,
        RenderCustomCell,
      }),
    [
      activeTab,
      clearFilter,
      suppressLoading,
      selectedYearMonth,
      MutipleSelectFilter,
      MultipleSelectFilterFunction,
      RenderCustomCell,
    ],
  )

  const TabTextSuffix = useCallback(
    ({ tab }) => {
      const { total } = filteredByTab[tab.tabId]
      const checked = total === 0

      if (checked) {
        return (
          <TabCheckedStyled>
            <CheckIcon />
          </TabCheckedStyled>
        )
      }

      return <TabCountStyled>{total}</TabCountStyled>
    },
    [filteredByTab],
  )

  const renderTabPane = () => {
    const allTabs = tabs.map((tabItem) => ({
      ...tabItem,
      TextSuffix: TabTextSuffix,
    }))
    return <TabPane2 tabs={allTabs} />
  }

  const clearAllFilters = () => {
    setClearFilter(true)
  }

  const handleSubmit = useCallback(() => {
    actions.showEnergyStarSubmissionModal({
      closeModal: actions.hideModal,
    })
    const selectedIds = selectedRows.map(({ original: { id } }) => id)
    setSelectedRows([])
    clearAllFilters()
    actions.uploadSubmissionsData({
      submissionIds: selectedIds,
      yearMonth: selectedYearMonth,
    })
  }, [actions, selectedRows, selectedYearMonth])

  const handleReSync = useCallback(() => {
    const selectedIds = selectedRows.map(({ original: { id } }) => id)
    setRowData(rowData)
    clearAllFilters()
    actions.reSyncSubmissionsData({
      submissionIds: selectedIds,
      yearMonth: selectedYearMonth,
    })
  }, [actions, rowData, selectedRows, selectedYearMonth])

  const isLastMonth = useMemo(
    () => moment().subtract(1, 'month').format('YYYY-MM') === selectedYearMonth,
    [selectedYearMonth],
  )

  const renderMonthPicker = useCallback(
    () => (
      <MonthPickerWrapperStyles isDisabled={suppressLoading}>
        <MonthPicker
          activeMonth={selectedYearMonth}
          showMonthPickerDropDown={showMonthPickerDropDown}
          setShowMonthPickerDropDown={setShowMonthPickerDropDown}
          onCancel={() => {
            setShowMonthPickerDropDown(false)
          }}
          onSubmit={(month) => {
            setSelectedRows([])
            clearAllFilters()
            setSelectedYearMonth(month.format('YYYY-MM'))
            setShowMonthPickerDropDown(false)
          }}
        />
      </MonthPickerWrapperStyles>
    ),
    [selectedYearMonth, showMonthPickerDropDown, suppressLoading],
  )

  const getESGCSVData = useMemo(() => {
    const fields = TableColumns.map((column) => ({
      label: column.Header as string,
      value: column.accessor as string,
    }))
    fields.push({
      label: 'ESPM Meter Id',
      value: 'espmMeterId',
    })
    fields.push({
      label: 'ESPM Start Date',
      value: 'espmStartdate',
    })

    const json2csvParser = new Parser({ fields })
    return json2csvParser.parse(
      exportData?.[activeTab as keyof typeof exportData],
    )
  }, [exportData, activeTab, TableColumns])

  const downloadCSV = useCallback(() => {
    const file = new Blob([getESGCSVData], { type: 'text/csv' })
    const date = moment(selectedYearMonth, 'YYYY-MM').format('MMYYYY')
    const fileName = `ESPM_${
      activeTab === tabsEnum.NOT_SUBMITTED ? 'NOT_' : ''
    }SUBMITTED_SUBMISSIONS_${date}.csv`
    return FileSaver.saveAs(file, fileName)
  }, [getESGCSVData, exportData, activeTab, TableColumns, selectedYearMonth])

  const HeaderDownloadButtons = useCallback(
    () => (
      <>
        {renderMonthPicker()}

        <HeaderButton onClick={downloadCSV} type='outlined'>
          <FileDownloadIcon />
          Download CSV
        </HeaderButton>

        {!!selectedRows.length && isLastMonth && (
          <HeaderButton
            onClick={handleReSync}
            loading={suppressLoading}
            type='outlined'
          >
            <RefreshIcon />
            <span>Re-Sync</span>
          </HeaderButton>
        )}
        {!!selectedRows.length && (
          <SubmitButton
            disabled={suppressLoading}
            onClick={handleSubmit}
            type='secondary'
          >
            Submit
          </SubmitButton>
        )}
        {/* $FlowFixMe */}
        {Object.values(filterValues).some((v) => v.length) && (
          <SubmitButton onClick={clearAllFilters} type='transparent'>
            Clear Filters
          </SubmitButton>
        )}
      </>
    ),
    [
      filterValues,
      handleReSync,
      handleSubmit,
      isLastMonth,
      renderMonthPicker,
      selectedRows.length,
      suppressLoading,
    ],
  )

  const getRowProps = React.useCallback((row: Row<TSEnergyStarSubmissions>) => {
    const {
      original: { status },
    } = row
    const fields: { highlightColor?: string } = {}

    if (status === statusEnum.IN_PROGRESS) {
      fields.highlightColor = colors.orange2
    }

    return fields
  }, [])

  if (energyStarSubmissionsLoading) {
    return (
      <LoadingSpinnerWrapperStyled>
        <Spinner size='medium' />
      </LoadingSpinnerWrapperStyled>
    )
  }

  return (
    <SubmissionPageOuter suppressLoading={suppressLoading}>
      <ActionPaneView renderMain={renderTabPane} actions={[]} />
      {tableData.length ?
        <TableWrapperStyled suppressLoading={suppressLoading}>
          <RedaptiveReactTable7
            columns={TableColumns}
            HeaderActions={HeaderDownloadButtons}
            data={tableData}
            defaultSort={defaultSort}
            getRowProps={getRowProps}
            alwaysLockedColumns={alwaysLockedColumns}
            enableColumnHiding
            enablePagination
            filterable
            enableRowSelection={activeTab === tabsEnum.NOT_SUBMITTED}
            showSelectAllLabel
            globalFilterable={false}
            getSelectedRowsData={setSelectedRows}
            tableInstanceRef={summarytableInstanceRef}
            fixedHeader
          />
        </TableWrapperStyled>
      : <>
          {renderMonthPicker()}
          <ConfirmationScreen>
            <ConfirmationTitleStyled>No Submissions</ConfirmationTitleStyled>
            <h2>Please submit customer meter data for Energy Star Rating.</h2>
          </ConfirmationScreen>
        </>
      }
    </SubmissionPageOuter>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(energyStarIntegrationActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(messagesActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const entity = energyStarSubmissionsEntity(state)
  const { submissionsData } = entity
  const {
    meta: { loading: energyStarSubmissionsLoading, suppressLoading },
  } = entity
  const authGroups = authSelectors.selectGroups(state)
  const {
    auth: { permissions },
  } = state
  return {
    authGroups,
    submissionsData,
    energyStarSubmissionsLoading,
    suppressLoading,
    permissions,
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EnergyStarSubmissionPage)
