/* eslint-disable react/no-array-index-key */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import ActionPaneView from './ActionPaneView'
import Breadcrumbs from './Breadcrumbs'
import CircuitTable from './CircuitTable'
import GasMeterConfiguration from './GasMeterConfiguration'
import NotVerifiedIcon from './Icons/NotVerifiedIcon'
import VerifiedIcon from './Icons/VerifiedIcon'
import InfoSection from './InfoSection'
import MeterConfiguration from './MeterConfiguration'
import withNotice from './MeterConfiguration/withNotice'
import MeterInstallLocationAndPowerSource from './MeterInstallLocationAndPowerSource'
import MeterUtilityAccount from './MeterUtilityAccount'
import StatusIconItem from './StatusIconItem'
import StyledLink from './StyledLink'
import TabPane from './Tabs/TabPane'
import Title from './Title'
import VerticalTable from './VerticalTable'
import { Label } from './VerticalTable/Base'
import withPermissions from '../authorization/components/withPermissions'
import features from '../authorization/features'
import { selectors as authSelectors } from '../ducks/auth'
import {
  actions as circuitActions,
  getCircuitListEntity,
  getCircuitsById,
  utils as circuitUtils,
} from '../ducks/circuits'
import type { FTCircuitsById } from '../ducks/circuits'
import { actions as messageActions } from '../ducks/messageFlasher'
import {
  actions as meterActions,
  selectKFactorHistoryItems,
  selectMeterEntity,
  selectStepDownTransformersItems,
} from '../ducks/meters'
import { getConditionalFields } from '../ducks/meters/generation'
import { actions as modalActions } from '../ducks/modal'
import {
  actions as panelActions,
  selectAllById as selectPanelsById,
  selectPanelListEntity,
} from '../ducks/panels'
import type { FTPanel } from '../ducks/panels'
import { actions as siteActions, selectSiteListEntity } from '../ducks/sites'
import { renderTimestamp } from '../ducks/utils'
import { FTRouterLocation } from '../types'

type FTProps = FTRouterLocation & {
  circuitsById: FTCircuitsById
  isAdmin: boolean
}
type FTState = {
  isKFactorModalOpen: boolean
}
const InlineVerifiedIcon = styled(VerifiedIcon)`
  font-size: 16px;
  vertical-align: text-top;
`
const InlineNotVerifiedIcon = styled(NotVerifiedIcon)`
  font-size: 16px;
  vertical-align: text-top;
`
const HeaderTablesStyles = styled.div`
  display: flex;

  > * {
    width: 50%;

    &:first-child {
      padding-right: 20px;
    }
  }

  ${Label} {
    float: none;
    min-width: 210px;

    @media (min-width: 1276px) {
      float: left;
    }
  }
`
const GasFirmwareRow = styled.div`
  display: flex;
`

class MeterDetail extends React.PureComponent<FTProps, FTState> {
  componentDidMount() {
    this.fetchMeter()
    this.props.actions.fetchStepDownTransformers()
  }

  componentDidUpdate(prev) {
    const {
      meterEntity: { item: prevMeter },
    } = prev
    const {
      meterEntity: { item: meter },
    } = this.props

    if (!prevMeter && meter && meter.id) {
      this.onMeterLoad()
    }

    if (prevMeter && meter && prevMeter.opportunityId !== meter.opportunityId) {
      this.props.actions.hideModal()
    }
  }

  onMeterLoad = () => {
    const {
      actions,
      meterEntity: { item: meter },
    } = this.props
    this.props.actions.loadAllCircuits(this.getCircuitListFetchProps())
    this.fetchPanels()

    if (meter && meter.resource === 'NATURAL_GAS') {
      actions.fetchKFactorHistory({
        id: meter.id,
      })
    }
  }

  openKFactorModal = (updateMode) => {
    const {
      actions,
      meterEntity: { item: meter },
    } = this.props
    actions.showModalKfactorBase({
      meterId: meter.id,
      meter,
      closeModal: this.closeKFactorModal,
      updateMode,
    })
  }

  closeKFactorModal = () => {
    const { actions } = this.props
    actions.hideModal()
  }

  getCircuitListFetchProps = () => {
    const {
      meterEntity: { item: meter },
    } = this.props
    const { source, id: meterId } = meter
    return circuitUtils.getCircuitListFetchProps({
      source,
      meterId,
    })
  }

  onMeterLocationAndPowerSourceUpdate = (body) => {
    const {
      meterEntity: { item: meter },
      actions,
    } = this.props
    const requestData = {
      id: meter.id,
      meterPowerSource: body.meterPowerSource,
      installLocation: body.installLocation,
      powerSource: {
        vtapL1PhaseA: body.vtapL1PhaseA,
      },
    }
    actions.updateMeter(requestData)
  }

  onMeterUtilityAccountUpdate = (body) => {
    const {
      meterEntity: { item: meter },
      actions,
    } = this.props
    const requestData = {
      meterId: meter.id,
      ...body,
    }
    actions.updateUtilityAccount(requestData)
    this.fetchMeter()
  }

  onMeterConfigUpdate = () => {
    const { actions } = this.props
    actions.hideMessages()
    this.fetchMeter()
    this.fetchPanels()
  }

  fetchMeter = () => {
    const {
      actions,
      match: {
        params: { meterId },
      },
    } = this.props
    actions.fetchMeter({
      id: meterId,
    })
  }

  fetchPanels = () => {
    const {
      actions,
      meterEntity: { item: meter },
    } = this.props
    const { siteId } = meter
    actions.fetchAllPanels({
      siteId,
    })
    actions.fetchSite({
      siteId,
    })
  }

  getMeterDetailFieldsFirst = () => {
    const {
      meterEntity: { item: meter },
      match: {
        params: { siteId },
      },
      location: { pathname },
    } = this.props
    const { source } = meter
    const isRedaptive = source === 'REDAPTIVE'
    const customerLink = `/account-management/customers/${meter.customerId}`
    const siteLink =
      siteId ?
        pathname.replace(/\/sites\/.*$/, `/sites/${meter.siteId}`)
      : pathname.replace(/\/meters\/.*$/, `/sites/${meter.siteId}`)
    return [
      {
        label: 'Meter Identifier',
        value: meter.name,
      },
      {
        label: 'Customer',
        value: meter.customerName,
        renderField: () => (
          <StyledLink to={customerLink} href={customerLink}>
            {meter.customerName}
          </StyledLink>
        ),
      },
      {
        label: 'Site',
        value: meter.siteName,
        renderField: () => (
          <StyledLink to={siteLink} href={siteLink}>
            {meter.siteName}
          </StyledLink>
        ),
      },
      {
        label: 'Display',
        value: meter.display,
        renderField: () =>
          meter.display ? <div>{meter.display}</div> : <div>Not Available</div>,
      },
      {
        label: 'Description',
        value: meter.description,
      },
      {
        label: 'Meter Type',
        value: meter.meterType,
      },
      {
        label: 'Model',
        value: meter.model,
      },
      {
        label: 'Generation',
        value: isRedaptive ? meter.generationName : `N/A (${meter.meterType})`,
      },
      {
        label: 'Opportunity',
        value:
          meter?.opportunityId ?
            `${meter.opportunityId} ${
              meter.opportunityType ? `(${meter.opportunityType})` : ''
            }`
          : 'Not Available',
      },
    ]
  }

  getMeterDetailFieldsSecond = () => {
    const {
      meterEntity: { item: meter },
    } = this.props
    const { source } = meter
    const isRedaptive = source === 'REDAPTIVE' || source === 'REDAPTIVE_GAS'
    const electronVerifiedField =
      isRedaptive ?
        [
          {
            label: 'Electron Verified',
            value:
              meter.verified ?
                <>
                  <InlineVerifiedIcon />
                  {' Yes'}
                </>
              : <>
                  <InlineNotVerifiedIcon />
                  {' No'}
                </>,
          },
        ]
      : []
    const electronVerifiedDateField =
      isRedaptive ?
        [
          {
            label: 'Electron Verified Date',
            value: meter.verifiedDateFormatted || 'Not Verified',
          },
        ]
      : []
    return [
      {
        label: 'Online Status',
        value: meter.meterStatus,
        renderField: () => <StatusIconItem status={meter.meterOnline} />,
      },
      {
        label: 'Field Status',
        value: meter.active,
        renderField: () => (
          <StatusIconItem
            status={meter.active}
            labelText={
              meter.active ? 'Active' : meter.deactivationReason || 'Other'
            }
          />
        ),
      },
      ...electronVerifiedField,
      {
        label: 'Labeled Channels',
        value: `${meter.labeledChannels} / ${meter.totalChannels} total`,
      },
      {
        label: 'First Configuration Date',
        value: meter.firstConfigDate,
      },
      {
        label: 'Most Recent Configuration Date',
        value: meter.mostRecentConfigurationDate,
      },
      ...electronVerifiedDateField,
      {
        label: 'Most Recent Salesforce Update',
        value: meter.dateMostRecentSalesforceUpdate,
      },
      {
        label: 'Most Recent Data Report',
        value: meter.lastReportDate,
      },
    ]
  }

  getFirmwareFields = () => {
    const {
      meterEntity: { item: meter },
    } = this.props
    return [
      {
        label: 'Serial Number',
        value: meter.serialNumber,
      },
      {
        label: 'Hardware Version',
        value: meter.hardwareVersion,
      },
      {
        label: 'OS Version at Manufacture',
        value: meter.manufactureOs,
      },
      {
        label: 'Data Packet Version at Manufacture',
        value: meter.manufactureDataPacketVersion,
      },
      {
        label: 'Cellular Carrier',
        value: meter.cellularCarrier,
      },
      {
        label: 'SIM Card ICCID',
        value: meter.simCardICCID,
      },
      {
        label: 'First Factory Logfile Received',
        value: meter.firstReportDate,
      },
    ]
  }

  renderCircuits = () => {
    const {
      circuitListEntity,
      meterEntity: { item: meter },
    } = this.props
    return meter ?
        <CircuitTable meter={meter} circuitListEntity={circuitListEntity} />
      : null
  }

  renderConfiguration = () => {
    const {
      circuitsById,
      meterEntity: {
        item: meter,
        meta: { loading },
      },
      panelListEntity,
      panelsById,
      meterConfig,
      actions: {
        deletePanel,
        updateMeterConfig,
        showConfirmModal,
        showModalPanelForm,
      },
      stepDownTransformers,
    } = this.props
    const { allItems: panelsSite = [], meta: panelsMeta = {} } =
      panelListEntity || {}
    const { generation = 'UNKNOWN' } = meter || {}
    const { getVoltageValues, panelTypes } = getConditionalFields(generation)
    const panels = panelsSite.filter(
      ({ type, voltage }: FTPanel) =>
        panelTypes.includes(type) && getVoltageValues(type).includes(voltage),
    )

    if (loading || !meter || !Object.keys(circuitsById).length) {
      return <div>Loading...</div>
    }

    if (meter.source !== 'REDAPTIVE') {
      return this.renderUnderDev()
    }

    return (
      <MeterConfiguration
        circuitsById={circuitsById}
        deletePanel={deletePanel}
        meter={meter}
        meterConfig={meterConfig}
        panels={panels}
        panelsById={panelsById}
        panelsMeta={panelsMeta}
        handleSubmit={updateMeterConfig}
        handleSuccess={this.onMeterConfigUpdate}
        showConfirmModal={showConfirmModal}
        showModalPanelForm={showModalPanelForm}
        stepDownTransformers={stepDownTransformers}
      />
    )
  }

  renderUnderDev = () => <div>Under Development</div>

  renderFirmware = () => {
    const {
      meterEntity: { item: meter },
    } = this.props
    const fields = meter ? this.getFirmwareFields() : []
    return <VerticalTable.Basic fields={fields} />
  }

  renderBreadcrumbs = () => {
    const {
      location: { pathname },
      meterEntity: { item: meter },
    } = this.props
    let items = []

    if (meter) {
      items = [
        {
          href: '/account-management',
          text: 'Accounts',
        },
      ]
      const {
        match: {
          params: { siteId, customerId },
        },
        meterEntity,
      } = this.props

      if (!siteId && !customerId) {
        items = [
          ...items,
          {
            href: '/account-management/meters',
            text: 'Meters',
          },
        ]
      } else if (meterEntity.item) {
        const { customerName, siteName } = meterEntity.item

        if (customerId) {
          items = [
            ...items,
            {
              href: '/account-management/customers',
              text: 'Customers',
            },
            {
              href: `/account-management/customers/${customerId}`,
              text: customerName,
            },
          ]
        } else {
          items = [
            ...items,
            {
              href: '/account-management/sites',
              text: 'Sites',
            },
          ]
        }

        items = [
          ...items,
          {
            href: pathname.replace(/\/sites\/.*/, `/sites/${siteId}`),
            text: siteName,
          },
          {
            href: pathname.replace(/\/meters\/.*/, '/meters'),
            text: 'Meters',
          },
        ]
      }
    }

    return <Breadcrumbs items={items} />
  }

  getGasMeterSensorsFields = () => {
    const {
      meterEntity: { item: meter },
    } = this.props
    const birthCertificate =
      meter ?
        meter?.meterDetailResponse?.gasMeterDetailResponse?.birthCertificate
      : {}
    const sensors =
      birthCertificate?.data_acquisition_modules.length > 0 ?
        birthCertificate?.data_acquisition_modules[0]?.sensors
      : []
    const values = [
      {
        label: 'Type',
        value: sensors[0]?.type,
      },
      {
        label: 'Make',
        value: sensors[0]?.make,
      },
      {
        label: 'Model',
        value: sensors[0]?.model,
      },
      {
        label: 'Model Number',
        value: sensors[0]?.model_number,
      },
      {
        label: 'Serial',
        value: sensors[0]?.serial,
      },
    ]
    return [
      {
        header: null,
        values,
      },
    ]
  }

  getGasMeterFirmwareFields = () => {
    const {
      meterEntity: { item: meter },
    } = this.props
    const birthCertificate =
      meter ?
        meter?.meterDetailResponse?.gasMeterDetailResponse?.birthCertificate
      : {}
    const dataTables = [
      {
        header: null,
        values: [
          {
            label: 'System Serial',
            value: birthCertificate.system_serial,
          },
        ],
      },
      {
        header: {
          label: 'CPU',
          value: '',
        },
        values: [
          {
            label: 'Make',
            value: birthCertificate?.cpu?.make,
          },
          {
            label: 'Model',
            value: birthCertificate?.cpu?.model,
          },
          {
            label: 'Model Number',
            value: birthCertificate?.cpu?.model_number,
          },
          {
            label: 'Serial',
            value: birthCertificate?.cpu?.serial,
          },
          {
            label: 'MAC',
            value: birthCertificate?.cpu?.mac,
          },
          {
            label: 'Hardware ID',
            value: birthCertificate?.cpu?.hardware_id,
          },
          {
            label: 'OS Version At Manufacture',
            value: birthCertificate?.cpu?.os_verison_at_manufacture,
          },
        ],
      },
      {
        header: {
          label: 'Data Acquisition Modules',
          value: '',
        },
        values: [
          {
            label: 'Make',
            value: birthCertificate?.data_acquisition_modules[0]?.make,
          },
          {
            label: 'Model',
            value: birthCertificate?.data_acquisition_modules[0]?.model,
          },
          {
            label: 'Model Number',
            value: birthCertificate?.data_acquisition_modules[0]?.model_number,
          },
          {
            label: 'Serial',
            value: birthCertificate?.data_acquisition_modules[0]?.serial,
          },
          {
            label: 'FW Version',
            value: birthCertificate?.data_acquisition_modules[0]?.fw_version,
          },
        ],
      },
      {
        header: {
          label: 'Cell Router',
          value: '',
        },
        values: [
          {
            label: 'Make',
            value: birthCertificate?.cell_router?.make,
          },
          {
            label: 'Model',
            value: birthCertificate?.cell_router?.model,
          },
          {
            label: 'Model Number',
            value: birthCertificate?.cell_router?.model_number,
          },
          {
            label: 'Serial',
            value: birthCertificate?.cell_router?.serial,
          },
          {
            label: 'WAN MAC',
            value: birthCertificate?.cell_router?.wan_mac,
          },
          {
            label: 'FW Version',
            value: birthCertificate?.cell_router?.fw_version,
          },
          {
            label: 'IMEI',
            value: birthCertificate?.cell_router?.imei,
          },
          {
            label: 'ICCID',
            value: birthCertificate?.cell_router?.iccid,
          },
        ],
      },
    ]
    return dataTables
  }

  renderGasMeterSensors = () => {
    const {
      meterEntity: { item: meter },
    } = this.props
    const info = meter ? this.getGasMeterSensorsFields() : []
    return <InfoSection info={info} />
  }

  renderGasMeterFirmware = () => {
    const {
      meterEntity: { item: meter },
    } = this.props
    const fields = meter ? this.getGasMeterFirmwareFields() : {}
    const sections = [[fields[0], fields[1], fields[2]], [fields[3]]]
    return (
      <GasFirmwareRow>
        {sections.map((section, i) => (
          <div key={`section${i}`}>
            <InfoSection info={section} />
          </div>
        ))}
      </GasFirmwareRow>
    )
  }

  renderGasMeterConfiguration = () => {
    const { kFactorHistoryItems, permissions } = this.props
    const calculationTypes = {
      UTILITY_METER: 'Manufacturer',
      MANUAL: 'Manual',
      CALCULATED: 'Calculated',
    }
    const data =
      kFactorHistoryItems ?
        kFactorHistoryItems.map((item, i) => ({
          ...item,
          status: i === 0 ? 'Active' : 'Archived',
          comment: item.comment || '-',
          createdFormattedDate: renderTimestamp(
            item.created,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            'MM-DD-YY h:mm A z',
          ),
          calculationType: calculationTypes[item.calculationType],
        }))
      : []
    const lastModifiedDate =
      data.length > 0 ?
        renderTimestamp(
          data[0].created,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'YYYY-MM-DD H:mm A z',
        )
      : ''
    const canEditMeterConfig =
      features.editMeterConfig.allMatchWithPermissions(permissions)
    return (
      <GasMeterConfiguration
        data={data}
        lastModified={lastModifiedDate}
        openKFactorModal={this.openKFactorModal}
        canEditMeterConfig={canEditMeterConfig}
      />
    )
  }

  renderInstallLocationAndPowerSource = () => {
    const {
      meterEntity: {
        item: meter,
        meta: { loading },
      },
    } = this.props
    return (
      <MeterInstallLocationAndPowerSource
        meter={meter}
        loading={loading}
        handleUpdate={this.onMeterLocationAndPowerSourceUpdate}
      />
    )
  }

  renderUtilityAccount = () => {
    const {
      meterEntity: {
        item: meter,
        meta: { loading },
      },
    } = this.props
    return (
      <MeterUtilityAccount
        loading={loading}
        meter={meter}
        handleUpdate={this.onMeterUtilityAccountUpdate}
      />
    )
  }

  renderMain = () => {
    const {
      match: { url },
      location: { pathname },
      meterEntity: {
        item: meter,
        meta: { loading },
      },
      circuitListEntity: { items: circuits },
      permissions,
    } = this.props
    const fieldsFirst = meter ? this.getMeterDetailFieldsFirst() : []
    const fieldsSecond = meter ? this.getMeterDetailFieldsSecond() : []
    const canEditMeters =
      features.editMeters.anyMatchesWithPermissions(permissions)
    const canEditMeterConfig =
      features.editMeterConfig.allMatchWithPermissions(permissions)
    const showMeterConfigLink =
      canEditMeterConfig &&
      meter &&
      meter.source === 'REDAPTIVE' &&
      !pathname.endsWith('/configuration/edit')
    const editChannelsLink =
      canEditMeters ?
        {
          href: `${url}/circuits/edit`,
          text: 'Edit',
          withDefaultTab: true,
        }
      : undefined
    const editMeterConfigLink =
      showMeterConfigLink ?
        {
          href: `${url}/configuration/edit`,
          text: 'Edit',
        }
      : undefined
    let editInstalLocation =
      canEditMeters ?
        {
          href: `${url}/install-location/edit`,
          text: 'Edit',
        }
      : undefined
    let editUtilityAccount =
      canEditMeters ?
        {
          href: `${url}/utility-account/edit`,
          text: 'Edit',
        }
      : undefined

    if (pathname.endsWith('/install-location/edit')) {
      editInstalLocation = {
        href: `${url}/install-location`,
        text: 'Cancel',
      }
    }

    if (pathname.endsWith('/utility-account/edit')) {
      editUtilityAccount = {
        href: `${url}/utility-account`,
        text: 'Cancel',
      }
    }

    const tabTypes = {
      electricity: [
        {
          tab: 'circuits',
          text: 'Channel Tags',
          render: this.renderCircuits,
          rightHandLink: editChannelsLink,
        },
        {
          tab: 'network',
          text: 'Network',
          render: this.renderUnderDev,
        },
        {
          tab: 'firmware',
          text: 'Hardware & Firmware',
          render: this.renderFirmware,
        },
        {
          tab: 'configuration',
          text: 'Configuration',
          render: this.renderConfiguration,
          rightHandLink: editMeterConfigLink,
        },
      ],
      naturalGas: [
        {
          tab: 'sensors',
          text: 'Sensors',
          render: this.renderGasMeterSensors,
        },
        {
          tab: 'firmware',
          text: 'Hardware & Firmware',
          render: this.renderGasMeterFirmware,
        },
        {
          tab: 'install-location',
          text: 'Install Location & Power Source',
          render: this.renderInstallLocationAndPowerSource,
          rightHandLink: editInstalLocation,
        },
        {
          tab: 'utility-account',
          text: 'Utility Account',
          render: this.renderUtilityAccount,
          rightHandLink: editUtilityAccount,
        },
        {
          tab: 'configuration',
          text: 'Configuration',
          render: this.renderGasMeterConfiguration,
        },
      ],
    }
    const tabs =
      meter && meter.resource === 'NATURAL_GAS' ?
        tabTypes.naturalGas
      : tabTypes.electricity
    return (
      <div>
        {this.renderBreadcrumbs()}
        <Title>Meter Detail</Title>
        {meter && (
          <HeaderTablesStyles>
            <VerticalTable.Basic fields={fieldsFirst} columned={false} />
            <VerticalTable.Basic fields={fieldsSecond} columned={false} />
          </HeaderTablesStyles>
        )}
        {circuits && !loading && <TabPane tabs={tabs} />}
      </div>
    )
  }

  getActionPaneActions = () => {
    const {
      actions,
      isAdmin,
      isAccountManagers,
      isOperationsSupport,
      meterEntity: { item: meter },
      siteListEntity: { items: sites = [] },
    } = this.props
    const { source, siteId } = meter || {}
    let meterActionArr = []
    const { contracts = [] } = sites.find((item) => item.id === siteId) || {}
    const isRedaptive = source === 'REDAPTIVE' || source === 'REDAPTIVE_GAS'
    if (isRedaptive && isAdmin) {
      meterActionArr = [
        {
          href: '',
          label: 'Change Electron Verified Status',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalElectronVerifiedForm(meter)
          },
        },
        {
          href: '',
          label: 'Change Field Status',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalFieldStatusForm(meter)
          },
        },
        {
          href: '',
          label: 'Opportunity Association',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalMeterContractAssociationForm({
              ...meter,
              opportunities: contracts,
            })
          },
        },
      ]
    } else if (isAdmin) {
      meterActionArr = [
        {
          href: '',
          label: 'Change Field Status',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalFieldStatusForm(meter)
          },
        },
        {
          href: '',
          label: 'Opportunity Association',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalMeterContractAssociationForm({
              ...meter,
              opportunities: contracts,
            })
          },
        },
      ]
    } else if (isAccountManagers || isOperationsSupport) {
      meterActionArr = [
        {
          href: '',
          label: 'Opportunity Association',
          onClick: (event: React.SyntheticEvent<HTMLLinkElement>) => {
            event.preventDefault()
            actions.showModalMeterContractAssociationForm({
              ...meter,
              opportunities: contracts,
            })
          },
        },
      ]
    }

    return meterActionArr
  }

  render() {
    return (
      <ActionPaneView
        actions={this.getActionPaneActions()}
        renderMain={this.renderMain}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(circuitActions, dispatch),
    ...bindActionCreators(messageActions, dispatch),
    ...bindActionCreators(meterActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(panelActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
  },
})

const mapStateToProps = (state, props) => {
  const authGroups = authSelectors.selectGroups(state)
  const isAdmin = authGroups && authGroups.includes('admin')
  const isAccountManagers =
    authGroups && authGroups.includes('account-managers')
  const isOperationsSupport =
    authGroups && authGroups.includes('operations-support')
  return {
    circuitListEntity: getCircuitListEntity(state),
    circuitsById: getCircuitsById(state),
    isAdmin,
    isAccountManagers,
    isOperationsSupport,
    meterConfig: state.meterConfig,
    meterEntity: selectMeterEntity(state, props.match.params.meterId),
    panelListEntity: selectPanelListEntity(state),
    panelsById: selectPanelsById(state),
    kFactorHistoryItems: selectKFactorHistoryItems(state),
    siteListEntity: selectSiteListEntity(state),
    stepDownTransformers: selectStepDownTransformersItems(state),
  }
}

export default withPermissions(
  withNotice(connect(mapStateToProps, mapDispatchToProps)(MeterDetail)),
)
