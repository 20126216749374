import { v4 as uuid } from 'uuid'

import { makeAlphanumericRange, makeListSelectorItem } from './index'
import type { FTPanelCircuit } from '../ducks/panelCircuits'
import { FTPanelListEntityMetaState } from '../ducks/panels'
import type { FTListSelectorItem } from '../types'

type FTItem = {
  name: string
  value: string | number
}

const makeListSelectorOptions = (
  items: Array<FTItem>,
): Array<FTListSelectorItem> =>
  items.map((item) => makeListSelectorItem(item.value, item.name))

export const removeDerivedFields = (
  circuits: Array<FTPanelCircuit>,
): Array<FTPanelCircuit> =>
  circuits.map((circuit) => {
    // TODO: Add phaseGroups into the request once it's supported
    const {
      phaseGroupSummary,
      macAddress,
      meterId,
      panelFeedName,
      ...supportedValues
    } = circuit

    if (circuit.isCreated) {
      const { id, isCreated, isMetered, ...otherValues } = supportedValues
      return { ...otherValues }
    }

    return supportedValues
  })
export const phaseOptions = makeListSelectorOptions([
  {
    name: 'A',
    value: 'A',
  },
  {
    name: 'B',
    value: 'B',
  },
  {
    name: 'C',
    value: 'C',
  },
])
export const validCtTypes = makeListSelectorOptions([
  {
    name: '15A',
    value: '15A',
  },
  {
    name: '30A',
    value: '30A',
  },
  {
    name: '50A',
    value: '50A',
  },
  {
    name: '100A',
    value: '100A',
  },
  {
    name: '120A',
    value: '120A',
  },
  {
    name: '150A',
    value: '150A',
  },
  {
    name: '200A',
    value: '200A',
  },
  {
    name: '600A',
    value: '600A',
  },
  {
    name: '1200A',
    value: '1200A',
  },
])
export const validAmperageValues = makeListSelectorOptions([
  {
    name: '20A',
    value: '20A',
  },
  {
    name: '30A',
    value: '30A',
  },
  {
    name: '40A',
    value: '40A',
  },
  {
    name: '50A',
    value: '50A',
  },
  {
    name: '60-70A',
    value: '60-70A',
  },
  {
    name: '90-100A',
    value: '90-100A',
  },
  {
    name: '100A',
    value: '100A',
  },
  {
    name: '125A',
    value: '125A',
  },
  {
    name: '150-175A',
    value: '150-175A',
  },
  {
    name: '200A',
    value: '200A',
  },
  {
    name: '225A',
    value: '225A',
  },
  {
    name: '250A',
    value: '250A',
  },
  {
    name: '300A',
    value: '300A',
  },
  {
    name: '400A',
    value: '400A',
  },
])

export const validSignalStrengthOptions = [
  {
    name: '4 Bars',
    id: 'FOUR',
  },
  {
    name: '3 Bars',
    id: 'THREE',
  },
  {
    name: '2 Bars',
    id: 'TWO',
  },
  {
    name: '1 Bar',
    id: 'ONE',
  },
  {
    name: '0 Bars',
    id: 'ZERO',
  },
]
export const validBreakerTypes = makeListSelectorOptions([
  {
    value: 'SINGLE_POLE_BREAKER',
    name: 'Single Pole',
  },
  {
    value: 'TANDEM_BREAKER',
    name: 'Tandem',
  },
  {
    value: 'DOUBLE_TAP_BREAKER',
    name: 'Double Tap',
  },
  {
    value: 'TWO_POLE_SAME_BREAKER',
    name: 'Two Pole Ganged',
  },
  {
    value: 'TWO_POLE_DIFFERENT_BREAKER',
    name: 'Two Pole',
  },
  {
    value: 'THREE_POLE_SAME_BREAKER',
    name: 'Three Pole Ganged',
  },
  {
    value: 'THREE_POLE_DIFFERENT_BREAKER',
    name: 'Three Pole',
  },
  {
    value: 'EMPTY_SLOT',
    name: 'Empty',
  }, // The following are not needed for now. Might add support later
  // { value: 'Switchboard', name: 'Switch board' },
])
export const MULTI_POLE_BREAKERS = [
  'TWO_POLE_SAME_BREAKER',
  'TWO_POLE_DIFFERENT_BREAKER',
  'THREE_POLE_SAME_BREAKER',
  'THREE_POLE_DIFFERENT_BREAKER',
]
export const MULTI_POLE_SAME_BREAKERS = [
  'TWO_POLE_SAME_BREAKER',
  'THREE_POLE_SAME_BREAKER',
]
export const TWO_POLE_BREAKERS = [
  'TWO_POLE_SAME_BREAKER',
  'TWO_POLE_DIFFERENT_BREAKER',
]
export const THREE_POLE_BREAKERS = [
  'THREE_POLE_SAME_BREAKER',
  'THREE_POLE_DIFFERENT_BREAKER',
]
export const SINGLE_POLE_BREAKERS = [
  'SINGLE_POLE_BREAKER',
  'TANDEM_BREAKER',
  'DOUBLE_TAP_BREAKER',
]
export const breakerNumbers = makeAlphanumericRange(1, 200).map((i) => ({
  id: i,
  name: `${i}`,
}))
export const getCreatedCircuits = (
  meta: FTPanelListEntityMetaState,
  isNumbered: boolean,
) => {
  let data = meta.temp
  if (!data) {
    data = {
      numberOfCircuits: isNumbered ? 2 : 1,
      leftSidePanel: 'ODD',
      phasingPattern: 'TOP_TO_BOTTOM',
    }
  }
  const { leftSidePanel, phasingPattern, numberOfSwitches } = data

  let { numberOfCircuits = isNumbered ? 2 : 1 } = data
  if (!isNumbered && numberOfSwitches) {
    numberOfCircuits = numberOfSwitches
  }

  if (!numberOfCircuits || numberOfCircuits < 1) {
    return {}
  }

  let phases = ['A', 'B', 'C']

  if (phasingPattern === 'BOTTOM_TO_TOP') {
    phases = ['C', 'B', 'A']
  }

  const circuitObjects = {}

  if (isNumbered) {
    if (leftSidePanel !== 'CONSECUTIVE') {
      const oddNumbers = []
      const evenNumbers = []

      for (let i = 1; i <= numberOfCircuits; i += 1) {
        if (i % 2 === 1) oddNumbers.push(i)
        else evenNumbers.push(i)
      }

      // First fill phases for Left side breakers
      const leftArray = leftSidePanel === 'ODD' ? oddNumbers : evenNumbers

      for (let i = 0, count = 0; i < leftArray.length; i += 1, count += 1) {
        const id = uuid()
        circuitObjects[id] = {
          id,
          breakerNumber: leftArray[i].toString(),
          sideBreakerOrder: 'LEFT',
          phasingPattern,
          phase: phases[count % 3],
        }
      }

      // Reverse order for Iline
      if (phasingPattern === 'I_LINE') phases.reverse()
      // Fill phases for right side breakers
      const rightArray = leftSidePanel === 'ODD' ? evenNumbers : oddNumbers

      for (let i = 0, count = 0; i < rightArray.length; i += 1, count += 1) {
        const id = uuid()
        circuitObjects[id] = {
          id,
          breakerNumber: rightArray[i].toString(),
          sideBreakerOrder: 'RIGHT',
          phasingPattern,
          phase: phases[count % 3],
        }
      }
    } else {
      for (
        let i = 1, count = 0;
        i <= Math.ceil(numberOfCircuits / 2);
        i += 1, count += 1
      ) {
        const id = uuid()
        circuitObjects[id] = {
          id,
          breakerNumber: i.toString(),
          sideBreakerOrder: 'LEFT',
          phasingPattern,
          phase: phases[count % 3],
        }
      }

      // Reverse order for Iline
      if (phasingPattern === 'I_LINE') phases.reverse()

      // Fill phases for right side breakers
      for (
        let i = Math.ceil(numberOfCircuits / 2) + 1, count = 0;
        i <= numberOfCircuits;
        i += 1, count += 1
      ) {
        const id = uuid()
        circuitObjects[id] = {
          id,
          breakerNumber: i,
          sideBreakerOrder: 'RIGHT',
          phasingPattern,
          phase: phases[count % 3],
        }
      }
    }
  } else {
    // For CONSECUTIVE
    for (let i = 1; i <= numberOfCircuits; i += 1) {
      const id = uuid()
      circuitObjects[id] = {
        id,
        breakerNumber: '',
        sideBreakerOrder: '',
        phasingPattern: 'TOP_TO_BOTTOM',
        phase: phases[(i - 1) % 3],
      }
    }
  }

  // For ODD and EVEN orders

  Object.keys(circuitObjects).forEach((key) => {
    circuitObjects[key] = {
      ...circuitObjects[key],
      isCreated: true,
      isMetered: false,
      breakerType: 'SINGLE_POLE_BREAKER',
      amperage: '',
      description: '',
      buildingSystemId: '',
      equipmentName: '',
      buildingArea: '',
      CTSizeExpected: '',
    }
  })
  return circuitObjects
}
// Replicates the circuit accordingly to it's breakerType
export const replicateCircuits = (
  breakerType: string,
  start: number,
  diff: number,
  copyFields: Record<string, any>,
  isNumbered: boolean,
) => {
  const { breakerNumber, ...fieldsWithoutBreakerNumber } = copyFields
  const fieldsToCopy = { ...fieldsWithoutBreakerNumber }
  const getNumberOfCircuits = (breaker) => {
    if (THREE_POLE_BREAKERS.includes(breaker)) {
      return 3
    }

    if (TWO_POLE_BREAKERS.includes(breaker)) {
      return 2
    }

    return 1
  }

  const numberOfCircuits = getNumberOfCircuits(breakerType)
  let newCircuits = {}

  for (let i = 0; i < numberOfCircuits; i += 1) {
    const id = uuid()

    const getBreakerNumber = () => {
      if (MULTI_POLE_SAME_BREAKERS.includes(breakerType)) {
        return (start + diff).toString()
      }

      if (breakerType === 'TANDEM_BREAKER') {
        return `${(start + (i + 1) * diff).toString()}A`
      }

      return (start + (i + 1) * diff).toString()
    }

    newCircuits = {
      ...newCircuits,
      [id]: {
        id,
        breakerType,
        isCreated: true,
        isMetered: false,
        breakerNumber: isNumbered ? getBreakerNumber() : breakerNumber,
        phase: ['A', 'B', 'C'][i % 3],
        ...fieldsToCopy,
      },
    }
  }

  return newCircuits
}

const getCircuitsByBreakerNumber = (circuits: Array<FTPanelCircuit>) =>
  circuits.reduce((prev, curr) => {
    const breakerNumber = parseInt(curr.breakerNumber, 10)
    const prevValue = prev[breakerNumber]

    if (prevValue) {
      return { ...prev, [breakerNumber]: [curr, ...prevValue] }
    }

    return { ...prev, [breakerNumber]: [curr] }
  }, {})

// Sorts and groups the circuits based on phase groups
export const getSortedCircuits = (circuits: Array<FTPanelCircuit>) => {
  const circuitByBreakerNumber = getCircuitsByBreakerNumber(circuits)
  const gangedBreakerNumbersMap = new Map()
  const gangedCircuitHeads = new Set([])
  const ungroupedCircuits = new Set(
    circuits
      .filter((circuit) => !circuit.phaseGroupSummary)
      .map((circuit) => circuit.id),
  )
  const visitedBreakers = []
  circuits
    .filter((circuit) => circuit.phaseGroupSummary)
    .forEach((circuit) => {
      const { name } = circuit.phaseGroupSummary || {}
      const phaseGroupBreakerNumbers = name
        .split('-')
        .map((breakerNumber) => parseInt(breakerNumber, 10))
      const head = phaseGroupBreakerNumbers.shift()
      if (!visitedBreakers.includes(parseInt(circuit.breakerNumber, 10))) {
        gangedCircuitHeads.add(circuit.id)
        phaseGroupBreakerNumbers.forEach((breakerNumber) => {
          if (head !== breakerNumber) visitedBreakers.push(breakerNumber)
        })
        visitedBreakers.push(head)
      }

      gangedBreakerNumbersMap.set(
        head,
        Array.from(new Set([head, ...phaseGroupBreakerNumbers])),
      )
    })
  return circuits
    .filter(
      (circuit) =>
        ungroupedCircuits.has(circuit.id) || gangedCircuitHeads.has(circuit.id),
    )
    .reduce((prev, curr) => {
      const gangedBreakerNumbers = gangedBreakerNumbersMap.get(
        parseInt(curr.breakerNumber, 10),
      )
      let gangedCircuits

      if (gangedBreakerNumbers) {
        // If it's a multi-pole-same-breaker
        if (gangedBreakerNumbers.length === 1) {
          gangedCircuits = circuitByBreakerNumber[gangedBreakerNumbers[0]]
        } else {
          gangedCircuits = gangedBreakerNumbers
            .filter((breakerNumber) => circuitByBreakerNumber[breakerNumber])
            .map((breakerNumber) => circuitByBreakerNumber[breakerNumber][0])
        }
      }

      return gangedCircuits ? [...prev, ...gangedCircuits] : [...prev, curr]
    }, [])
}
export const columnWidths: Record<
  string,
  {
    maxWidth: number
    minWidth: number
  }
> = {
  isMetered: {
    maxWidth: 80,
    minWidth: 80,
  },
  breakerNumber: {
    maxWidth: 90,
    minWidth: 90,
  },
  switchName: {
    maxWidth: 180,
    minWidth: 180,
  },
  phase: {
    maxWidth: 90,
    minWidth: 90,
  },
  breakerType: {
    maxWidth: 180,
    minWidth: 180,
  },
  phaseGroup: {
    maxWidth: 180,
    minWidth: 180,
  },
  description: {
    minWidth: 180,
    maxWidth: 180,
  },
  buildingSystemId: {
    maxWidth: 200,
    minWidth: 200,
  },
  equipmentName: {
    minWidth: 180,
    maxWidth: 180,
  },
  buildingArea: {
    minWidth: 180,
    maxWidth: 180,
  },
  amperage: {
    maxWidth: 120,
    minWidth: 120,
  },
  CTSizeExpected: {
    maxWidth: 120,
    minWidth: 120,
  },
}
