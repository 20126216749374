import { PureComponent, ReactNode } from 'react'

import CircuitPending from './CircuitPending'
import Disabled from './Disabled'
import type { FTPending } from '../ducks/types'
import '../ducks/types'

type FTField =
  | string
  | number
  | boolean
  | {
      current: FTPending
      pending?: FTPending | null | undefined
    }

const getField = (field: FTField) => {
  if (
    typeof field === 'string' ||
    typeof field === 'number' ||
    typeof field === 'boolean'
  ) {
    return {
      current: field,
      pending: undefined,
    }
  }

  return field
}

type FTProps = {
  field: FTField | null | undefined
  renderField?: (arg0: any) => ReactNode
}

class FieldWithPending extends PureComponent<FTProps> {
  static defaultProps = {
    renderField: undefined,
  }

  renderPending = (pending: any) => {
    const { renderField } = this.props

    if (!pending) {
      return null
    }

    const value = renderField ? renderField(pending) : pending
    return (
      <Disabled inline>
        <CircuitPending value={value} />
      </Disabled>
    )
  }

  render() {
    const { field, renderField } = this.props

    if (!field) {
      return null
    }

    // Allow passing in just the value itself as 'field' and convert to expected
    const payload: FTPending = getField(field)
    const { current, pending } = payload
    return (
      <>
        {!renderField && current}
        {renderField && renderField(current)}
        {current !== pending && this.renderPending(pending)}
      </>
    )
  }
}

export default FieldWithPending
